<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Attestato" @hidden="closeForm" @close="closeForm">
      <b-row>
        <b-col lg="2">
          <b-form-group label="Inizio">
            <b-form-input v-model="data.certificate.fromdate" type="date"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Fine">
            <b-form-input v-model="data.certificate.todate" type="date"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Rilasciato da">
            <b-form-input v-model="data.certificate.issuer"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Codice">
            <b-form-input v-model="data.certificate.code"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Durata (h)">
            <b-form-input v-model="data.certificate.duration" type="number"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-form-group label="Attestato / Certificato">
            <b-form-input v-model="data.certificate.degree"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Qualificato altro ente">
            <b-form-select v-model="data.certificate.recognisedbyothercb" :options="recognisedbyothercbCombo" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Valido 40 ore?">
            <b-form-select v-model="data.certificate.qualify40" :options="qualify40Combo" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Norme">
        <multiselect v-model="standardsSupport" :options="data.standards" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.certificate.standards = updateCombo(standardsSupport)"/>
      </b-form-group>
      <b-form-group label="IAFs">
        <multiselect v-model="iafcodesSupport" :options="data.iafcodes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.certificate.iafcodes = updateCombo(iafcodesSupport)"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.certificate.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "curriculumcertificate",
  data() {
    return {
      show: false,
      data: null,
      standardsSupport: [],
      iafcodesSupport: [],
      qualify40Combo: [{
        id: false,
        val: "Non valido",
      },{
        id: true,
        val: "Valido 40 ore",
      }],
      recognisedbyothercbCombo: [{
        id: false,
        val: "No"
      },{
        id: true,
        val: "Riconosciuto altro ente"
      }]
    }
  },
  computed: {
    winHeight(){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    rowClassDocuments(item) {
      return item !== null && item.datetime === null ? 'table-danger' : '';
    },
    loadData( usersId, id ){
      this.openHttp("curricula_certificate", { users_id: usersId, id: id }).then((result) => {
        this.standardsSupport = this.convertCombo(result.standards, result.certificate.standards);
        this.iafcodesSupport = this.convertCombo(result.iafcodes, result.certificate.iafcodes);
        this.data = result;
        this.show = true;
        this.loadAudits();
      })
    },
    saveData(){
      this.saveHttp("curricula_certificate_save", this.data.certificate, "Certificato salvato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("curricula_certificate_delete", { id: this.data.certificate.id, users_id: this.data.certificate.users_id }, "Certificato eliminato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.data = null;
      this.show = false;
      this.$emit('update');      
    }
  }
}
</script>
<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Company" scrollable @hidden="closeForm" @close="closeForm">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Kompani" active>
            <b-row>
              <b-col lg="10">
                <b-form-group label="Kompani">
                  <b-form-input v-model="data.company.company"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Nationality">
                  <b-form-select v-model="data.company.states_id" :options="data.states" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <b-form-group label="NIPT">
                  <b-form-input v-model="data.company.vat"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Fiscal code">
                  <b-form-input v-model="data.company.taxcode"/>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Supplier">
                  <b-form-select v-model="data.company.suppliers_id" :options="data.suppliers" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Marketing Note">
              <b-form-textarea v-model="data.company.marketingnote" rows="2"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Sites" :disabled="data.company.id === 0">
            <companysite ref="site" @update="loadData(data.company.id)"/>
            <b-btn variant="primary" size="sm" @click="$refs.site.loadData(0, data.company.id )">i ri</b-btn>
            <b-table :sticky-header="(winHeight - 380) + 'px'" class="mt-2" responsive :items="data.sites" :fields="fields.sites" @row-clicked="$refs.site.loadData( $event.id, data.company.id )"/>
          </b-tab>
          <b-tab title="Workers" :disabled="data.company.id === 0">
            <companyworker ref="worker" @update="loadData(data.company.id)"/>
            <b-btn variant="primary" size="sm" @click="loadWorker(0)">i ri</b-btn>
            <b-table :sticky-header="(winHeight - 380) + 'px'" class="mt-2" responsive :items="data.workers" :fields="fields.workers" @row-clicked="loadWorker($event.id)"/>
          </b-tab>
          <b-tab title="Contacts" :disabled="data.company.id === 0">
            <companycontact ref="contact" @update="loadData(data.company.id)"/>
            <b-btn variant="primary" size="sm" @click="loadContact( 0 )">i ri</b-btn>
            <b-table :sticky-header="(winHeight - 380) + 'px'" class="mt-2" responsive :items="data.contacts" :fields="fields.contacts" @row-clicked="loadContact($event.id)"/>
          </b-tab>
          <b-tab title="Scopes" :disabled="data.company.id === 0">
            <companyscope ref="scope" @update="loadData(data.company.id)"/>
            <b-btn variant="primary" size="sm" @click="loadScope( 0 )">i ri</b-btn>
            <b-table :sticky-header="(winHeight - 380) + 'px'" class="mt-2" responsive :items="data.scopes" :fields="fields.scopes" @row-clicked="loadScope($event.id)"/>
            <b-form-group label="IAF">
              <multiselect v-model="data.company.iafcodesSupport" :options="data.iafcodes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.company.iafcodes = updateCombo(data.company.iafcodesSupport)"/>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" v-show="data.company.id > 0" @click="loadData(data.company.id)">Rifresko</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData">Ruaj</b-btn>
          <b-btn variant="danger" v-show="data.company.id > 0" @click="deleteData">Fshi</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import companysite from './companycomponents/companysite.vue';
import companycontact from './companycomponents/companycontact.vue';
import companyworker from './companycomponents/companyworker.vue';
import companyscope from './companycomponents/companyscope.vue';

export default {
  name: "company",
  components: { companysite, companycontact, companyworker, companyscope },
  data() {
    return {
      show: false,
      data: null,
      fields: {
        sites: [{
          key: 'address',
          label: "Adresë",
        },{
          key: 'city',
          label: 'Qyteti',
          sortable: true,
        },{
          key: 'postalcode',
          label: 'Kodi Postar',
          sortable: true,
        },{
          key: 'region',
          label: "Rajon",
          sortable: true,
        },{
          key: "state",
          label: "Shtetit",
          sortable: true,
        },{
          key: 'active',
          label: 'Aktive',
          sortable: true,
          formatter: value => {
            return value === true ? 'Po' : 'Nr';
          },
        },{
          key: 'registered',
          label: 'Regjistruar',
          sortable: true,
          formatter: value => {
            return value === true ? 'Po' : 'Nr';
          },
        },{
          key: 'iafcodes',
          label: 'Kodet IAF'
        },{
          key: "totalworkers",
          label: "Totali i punëtorëve",
        },{
          key: "effectiveworkers",
          label: "Punëtorët efektivë",
        },{
          key: "subcontractorsworkers",
          label: "Punëtorët e nënkontraktorëve",
        },{
          key: "shiftsworkers",
          label: "Turne",
        },{
          key: "processesonsite",
          label: "Proceset në vend",
        },{
          key: "processessubcontractors",
          label: "Punëtorët e nënkontraktorëve",
        }],
        contacts: [{
          key: "contactstype",
          label: "Type",
          sortable: true
        },{
          key: "contact",
          label: "Contact",
          sortable: true
        },{
          key: "worker",
          label: "Worker",
        }],
        workers: [{
          key: "title",
          label: "Title",
        },{
          key: "firstname",
          label: "Nome",
          sortable: true
        },{
          key: "lastname",
          label: "Cognome",
          sortable: true
        },{
          key: 'active',
          label: "Stato",
          sortable: true,
          formatter: value => {
            return value === true ? 'Attivo' : 'Non attivo';
          },
        },{
          key: 'internal',
          label: "Interno",
          sortable: true,
          formatter: value => {
            return value === true ? 'Interno' : 'Esterno';
          },
        },{
          key: 'job',
          label: "Mansione",
        },{
          key: 'workersrolestypes',
          label: "Aree",
        },{
          key: 'note',
          label: "Note",
        }],
        scopes: [{
          key: "lang",
          label: "Language",
          sortable: true
        },{
          key: "scope",
          label: "Scope",
        },{
          key: "sites",
          label: "Sites",
        }],
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  methods: {
    rowClassWorker ( item ){
      return item.active === false ? "table-warning": "";
    },
    loadData(id){
      if ( this.data === null ) this.show = false;
      this.openHttp("companies_company", { id: id }).then((result) => {
        result.company.iafcodesSupport = this.convertCombo(result.iafcodes, result.company.iafcodes);
        this.data = result;
        this.show = true;
      })
    },
    loadWorker( id ){
      this.$refs.worker.loadData( id, this.data.company.id );
    },
    loadContact( id ){
      this.$refs.contact.loadData( id, this.data.company.id );
    },
    loadScope( id ){
      this.$refs.scope.loadData( id, this.data.company.id );
    },
    saveData(){
      this.saveHttp("companies_company_save", this.data.company).then((result) => {
        if ( result.result == true ) this.loadData(result.id);
      })
    },
    deleteData(){
      this.deleteHttp("companies_company_delete", { id: this.data.company.id }).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    }
  },
}
</script>
<template>
  <div>
    <b-modal v-model="show" v-if="data !== null" title="Scope">
      <b-form-group label="Lingua">
        <b-form-select v-model="data.scope.languages_id" :options="data.languages" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Scope">
        <b-form-textarea v-model="data.scope.scope" rows="4"/>
      </b-form-group>
      <b-form-group label="Sites">
        <multiselect v-model="data.scope.sitesSupport" :options="data.sites" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.scope.sites = updateCombo(data.scope.sitesSupport)"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
          <b-btn variant="danger" v-show="data.scope.id > 0" @click="deleteData">Delete</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "companycontact",
  data() {
    return {
      show: false,
      data: null,
    }
  },
  methods: {
    loadData( id, companies_id ){
      this.openHttp("companies_scope", { id: id, companies_id: companies_id }).then((result) => {
        result.scope.sitesSupport = this.convertCombo(result.sites, result.scope.sites);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("companies_scope_save", this.data.scope, "Scope saved!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){      
      this.deleteHttp("companies_scope_delete", { id: this.data.scope.id }, "Scope deleted!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    }
  },
}
</script>
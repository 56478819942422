<template>
  <div>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Authorizations" active>
          <usersform ref="form" @update="loadDataForms"/>
          <b-btn variant="primary" @click="loadDataForms">Rifresko</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="forms.items.data" :fields="forms.fields" :busy="forms.isBusy" :no-local-sorting="true" :sort-by.sync="forms.search.sort.by" :sort-desc.sync="forms.search.sort.desc" @sort-changed="sortingChangeForms" @row-clicked="loadForm($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
          </b-table>
          <tablebottom :busy="forms.isBusy" :currentpage="forms.search.page" :total="forms.items.total" @updateCurrentPage="forms.search.page = $event" @loadData="loadDataForms"/>
        </b-tab>
        <b-tab title="Suppliers">
          <supplier ref="supplier" @update="loadDataSuppliers"/>
          <b-btn class="mr-2" variant="primary" @click="loadDataSuppliers">Rifresko</b-btn>
          <b-btn variant="success" @click="loadSupplier(0)">New</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="suppliers.items.data" :fields="suppliers.fields" :busy="suppliers.isBusy" :no-local-sorting="true" :sort-by.sync="suppliers.search.sort.by" :sort-desc.sync="suppliers.search.sort.desc" @sort-changed="sortingChangeSuppliers" @row-clicked="loadSupplier($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
          </b-table>
          <tablebottom :busy="suppliers.isBusy" :currentpage="suppliers.search.page" :total="suppliers.items.total" @updateCurrentPage="suppliers.search.page = $event" @loadData="loadDataSuppliers"/>
        </b-tab>
        <b-tab title="Users">
          <usersuser ref="user" @update="loadDataUsers"/>
          <b-btn class="mr-2" variant="primary" @click="loadDataUsers">Rifresko</b-btn>
          <b-btn variant="success" @click="loadUser(0)">New</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="users.items.data" :fields="users.fields" :busy="users.isBusy" :no-local-sorting="true" :sort-by.sync="users.search.sort.by" :sort-desc.sync="users.search.sort.desc" @sort-changed="sortingChangeUsers" @row-clicked="loadUser($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
          </b-table>
          <tablebottom :busy="users.isBusy" :currentpage="users.search.page" :total="users.items.total" @updateCurrentPage="users.search.page = $event" @loadData="loadDataUsers"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import usersform from './usersform.vue'
import usersuser from './usersuser.vue'
import supplier from './supplier.vue'

export default {
  name: "users",
  components : {
    usersform, usersuser, supplier
  },
  data() {
    return {
      forms: {
        isBusy: true,
        search: {
          page: 1,
          sort: {
            by: 'form',
            desc: false,
          },
        },
        fields: [{
          key: 'form',
          label: 'Form',
          sortable: true,
        },{
          key: 'users',
          label: "Authorized users",
        }],
        items: {
          total: 0,
          data: [],
        }
      },
      suppliers: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {},
          sort: {
            by: 'active',
            desc: true,
          },
        },
        fields: [{
          key: 'supplier',
          label: 'Supplier',
          sortable: true,
        },{
          key: 'users',
          label: 'Users',
        }],
        items: {
          total: 0,
          data: [],
        }
      },
      users: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {},
          sort: {
            by: 'active',
            desc: true,
          },
        },
        fields: [{
          key: 'fullname',
          label: 'Fullname',
          sortable: true,
        },{
          key: 'username',
          label: 'Username',
          sortable: true,
        },{
          key: 'active',
          label: "Status",
          sortable: true,
          formatter: value => {
            return value === true ? 'Active' : 'Not Active'; 
          },
        },{
          key: 'suppliers',
          label: 'Suppliers',
        }],
        items: {
          total: 0,
          data: [],
        }
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadDataForms();
    this.loadDataSuppliers();
    this.loadDataUsers();
  },
  methods: {
    loadDataForms(){
      this.forms.isBusy = true;
      this.openHttp( "users_forms", this.forms.search ).then((result) => {
        this.forms.items = result;
        this.forms.isBusy = false;
      })
    },
    sortingChangeForms(ctx) {
      this.forms.search.sort.by = ctx.sortBy;
      this.forms.search.sort.desc = ctx.sortDesc;
      this.loadDataForms();
    },
    loadForm(id){
      this.$refs.form.loadData(id);
    },
    loadDataSuppliers(){
      this.suppliers.isBusy = true;
      this.openHttp( "users_suppliers", this.suppliers.search ).then((result) => {
        this.suppliers.items = result;
        this.suppliers.isBusy = false;
      })
    },
    sortingChangeSuppliers(ctx) {
      this.suppliers.search.sort.by = ctx.sortBy;
      this.suppliers.search.sort.desc = ctx.sortDesc;
      this.loadDataSuppliers();
    },
    loadSupplier(id){
      this.$refs.supplier.loadData(id);
    },
    loadDataUsers(){
      this.users.isBusy = true;
      this.openHttp( "users_users", this.users.search ).then((result) => {
        this.users.items = result;
        this.users.isBusy = false;
      })
    },
    sortingChangeUsers(ctx) {
      this.users.search.sort.by = ctx.sortBy;
      this.users.search.sort.desc = ctx.sortDesc;
      this.loadDataUsers();
    },
    loadUser(id){
      this.$refs.user.loadData(id);
    },
  }
}
</script>
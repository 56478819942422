<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Mospërputhje" scrollable @hidden="closeForm" @close="closeForm">
      <b-row>
        <b-col lg="4">
          <b-form-group label="Graviteti">
            <b-form-select v-model="data.nonconformity.gravity" :options="data.gravity" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-checkbox-group v-model="data.nonconformity.standardspoints">
        <b-form-group v-for="item in data.standards" :key="item.standard" :label="item.standard">
          <b-form-checkbox v-for="item2 in item.standardspoints" :key="item2.id" :value="item2.id">{{ item2.val }}</b-form-checkbox>          
        </b-form-group>
      </b-form-checkbox-group>
      <b-form-group label="Mospërputhje">
        <b-form-textarea v-model="data.nonconformity.nonconformity" rows="4"/>
      </b-form-group>
      <hr>
      <b-row>
        <b-col lg="4">
          <b-form-group label="Data e mbylljes">
            <b-form-input v-model="data.nonconformity.closuredate" type="date"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Shkaqet">
        <b-form-textarea v-model="data.nonconformity.causes" rows="4"/>
      </b-form-group>
      <b-form-group label="Mjekimi">
        <b-form-textarea v-model="data.nonconformity.treatment" rows="4"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Ruaj</b-btn>
          <b-btn variant="danger" v-show="data.nonconformity.id > 0" @click="deleteData">Fshije</b-btn>
        </div>
      </template>           
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "checklistnonconformity",
  data() {
    return {
      show: false,
      data: null
    }
  },
  methods: {
    loadData( id, auditsId ){
      this.openHttp("checklists_nonconformity", { id: id, audits_id: auditsId }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("checklists_nonconformity_save", this.data.nonconformity ).then((result) => {
        if ( result.result === true ) this.closeForm();
      })
    },
    deleteData( ){
      this.deleteHttp("checklists_nonconformity_delete", { id: this.data.nonconformity.id }).then((result) => {
        if ( result.result === true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>
<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" title="Documento" @hidden="closeForm" @close="closeForm">
            <b-form-group label="Nome file" v-if="data.document.filename !== null">
                <b-form-input v-model="data.document.filename"/>
            </b-form-group>
            <b-form-group label="Dettagli">
                {{ data.document.jsonkey }}
            </b-form-group>
            <b-form-group label="File">
                <b-form-file @change="fileChange"/>
            </b-form-group>
            <b-form-group v-show="data.document.id > 0" label="Bloccato da">
                <b-form-input v-model="data.document.lock_user"/>
            </b-form-group>
            <b-form-group v-show="data.document.id > 0" label="Bloccato data ed ora">
                <b-form-input v-model="data.document.lock_datetime"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" v-show="data.document.lock_datetime === null" class="mr-2" @click="saveData">Salva</b-btn>
                    <b-btn variant="danger" class="mr-2" v-show="data.document.id > 0 && data.document.lock_datetime === null" @click="deleteData">Elimina</b-btn>
                    <b-btn variant="primary" v-show="data.document.id > 0 && data.document.lock_datetime === null" @click="lockData">Blocca</b-btn>
                    <b-btn variant="warning" v-show="data.document.id > 0 && data.document.lock_datetime !== null" @click="unlockData">Sblocca</b-btn>
                </div>
            </template>
        </b-modal>
        <form method="post" action="/download.php" ref="downloadForm" target="_blank">
            <input type="hidden" name="token" :value="token">
            <input type="hidden" name="form" value="curricula">
            <input type="hidden" ref="downloadFormId" name="id">
        </form>
    </div>
</template>

<script>
export default {
    name: "curriculumdocument",
    data() {
        return {
            show: false,
            data: null,
        }
    },
    computed: {
        token(){
            return this.$store.getters.getToken;
        },
    },
    methods: {
        loadData( table, tableId, id ){
            this.show = false;
            this.openHttp("curricula_document", { table: table, table_id: tableId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        download_file(documentsId){
            this.$refs.downloadFormId.value = documentsId;
            this.$refs.downloadForm.submit();
        },
        fileChange(event){
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = ()=> {
                if ( this.data.document.id > 0 ){
                    this.data.document.filenamereal = event.target.files[0].name;
                } else {
                    this.data.document.filename = event.target.files[0].name.replace(/\.[^/.]+$/, "");
                    this.data.document.filenamereal = event.target.files[0].name;
                }
                this.data.document.file = reader.result;
            }
        },
        saveData(){
            this.saveHttp("curricula_document_save", this.data.document, "Documento salvato!" ).then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("curricula_document_delete", { table: this.data.document.table, tables_id: this.data.document.tables_id, id: this.data.document.id }, "Documento eliminato!" ).then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        lockData(){
            this.saveHttp("curricula_document_lock", { table: this.data.document.table, tables_id: this.data.document.tables_id, id: this.data.document.id }, "Documento bloccato!" ).then((result) => {
                if ( result.result == true ) this.closeForm(true);
            })
        },
        unlockData(){
            this.saveHttp("curricula_document_unlock", { table: this.data.document.table, tables_id: this.data.document.tables_id, id: this.data.document.id }, "Documento sbloccato!" ).then((result) => {
                if ( result.result == true ) this.closeForm(true);
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>
<template>
  <div>
    <b-modal v-model="show" title="Login" no-close-on-esc no-close-on-backdrop hide-header-close hide-footer>
      <b-form>
        <b-form-group label="Username">
          <b-form-input v-model="usr" required/>
        </b-form-group>
        <b-form-group label="Password">
          <b-form-input type="password" v-model="psw" required/>
        </b-form-group>
        <b-btn variant="primary" @click="login">Login</b-btn>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
      usr: null,
      psw: null,
    }
  },
  methods: {
    login(){
      this.loginHttp(this.usr, this.psw ).then((result) => {
        if ( result === true ) this.$router.replace("/homepage");
      })
    }
  }
}
</script>
<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" title="Dokumenti" size="lg" @hidden="closeForm" @close="closeForm">
            <b-form-group label="Emri i skedarit" v-if="data.document.filename !== null">
                <b-form-input v-model="data.document.filename"/>
            </b-form-group>
            <b-form-group label="Skedari">
                <b-form-file @change="fileChange"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Ruaj</b-btn>
                    <b-btn variant="danger" v-show="data.document.id > 0" @click="deleteData">Fshije</b-btn>
                </div>
            </template>
        </b-modal>
        <form method="post" action="/download.php" ref="downloadForm" target="_blank">
            <input type="hidden" name="token" :value="token">
            <input type="hidden" name="form" value="offers">
            <input type="hidden" ref="downloadFormId" name="id">
        </form>
        <form method="post" action="/downloadall.php" ref="downloadAllForm" target="_blank">
            <input type="hidden" name="token" :value="token">
            <input type="hidden" name="form" value="offers">
            <input type="hidden" ref="downloadFormIds" name="ids">
        </form>
    </div>
</template>

<script>
export default {
    name: "offerdocument",
    data() {
        return {
            show: false,
            data: null,
        }
    },
    computed: {
        token (){
            return this.$store.getters.getToken;
        },
    },
    methods: {
        loadData( contractsId, id ){
            this.show = false;
            this.openHttp("offers_document", { contracts_id: contractsId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        fileChange(event){
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = ()=> {
                if ( this.data.document.id > 0 ){
                    if ( event.target.files[0].name != this.data.filename ){
                        if ( confirm("Cambiare nome file?") == true) this.data.filename = event.target.files[0].name;
                    }
                } else {
                    this.data.document.filename = event.target.files[0].name;
                }
                this.data.document.file = reader.result;
            };
        },
        saveData(){
            this.saveHttp("offers_document_save", this.data.document).then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("offers_document_delete", { contracts_id: this.data.document.contracts_id, id: this.data.document.id }).then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        downloadDocument( id ){
            this.$refs.downloadFormId.value = id;
            this.$refs.downloadForm.submit();
        },
        downloadDocumentAll(){
            this.$refs.downloadFormIds.value = JSON.stringify(this.data.documents);
            this.$refs.downloadAllForm.submit();
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>
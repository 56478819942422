<template>
  <div id="app">
    <b-container fluid style="padding: 0px;">
      <navbar v-if="ready"/>
      <div :class="ready ? 'containter-white' : ''">
        <router-view/>
      </div>
    </b-container>
  </div>
</template>

<script>
import navbar from '@/components/navbar.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    navbar,
  },
  data(){
    return {
      ready: false,
    }
  },
  created() {
    if ( this.$route.meta.requiresAuth === false ){
      this.ready = false;
    } else {
      this.ready = this.isLogged;
    }
  },
  computed: {
    ...mapGetters(['isLogged', 'getWinHeight', 'getWinWidth']),
  },
  watch: {
    isLogged: function(val){
      console.log("asd");
      this.ready = val;
    },
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener('resize', this.getWindowWidth);
      window.addEventListener('resize', this.getWindowHeight);
      this.getWindowWidth();
      this.getWindowHeight();
    })
  },
  methods: {
    getWindowWidth() {
      this.$store.commit('setWinHeight', document.documentElement.clientHeight);
    },
    getWindowHeight() {
      this.$store.commit('setWinWidth', document.documentElement.clientWidth);
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth);
    window.removeEventListener('resize', this.getWindowHeight);
  }
}
</script>

<style>
html, body {
  height:100%;
  widows: 100%;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-height: 100%;
  background-color: rgba(0, 0, 0, 0.03);
}

.containter-white{
  margin: 10px;
  padding: 10px;
  background-color: white;
}

.modal-xl {
  height: calc(100% - 10px);
  min-width: calc(100% - 20px);
}

.modal-lg {
  /* height: calc(100% - 10px); */
  min-width: calc(70% - 20px);
}

.bottom-table {
  display: flex;
  margin:0;
  padding:0;
}

.bottom-table > .slot1 {
  width: 100%;
  height: 38px;
  padding-top: 7px;
  padding-left: 10px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.bottom-table > .slot2 {
  min-width: 100px;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Kontrata" scrollable @hidden="closeForm" @close="closeForm">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Kontrata" active>
            <b-row>
              <b-col lg="6">
                <company ref="company" @update="loadData(data.contract.id)"/>
                <b-form-group>
                  <label>Kompani</label> <b-link v-show="data.contract.id > 0" @click="$refs.company.loadData( data.contract.companies_id )"> Apri</b-link>
                  <b-form-select v-model="data.contract.companies_id" :options="data.companies" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Referuesi">
                  <b-form-select v-model="data.contract.workers_id" :options="data.workers.filter(x => x.companies_id === data.contract.companies_id)" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Kontrata">
                  <b-form-input v-model="data.contract.contract"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Tipo">
                  <b-form-select v-model="data.contract.contractstypes_id" :options="data.contractstypes" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Data e aplikimi">
                  <b-form-input type="date" v-model="data.contract.create_date"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Data e kontrates">
                  <b-form-input type="date" v-model="data.contract.contract_date"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Data e skadences">
                  <b-form-input type="date" v-model="data.contract.expiry_date"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="closure date">
                  <b-form-input type="date" v-model="data.contract.closure_date"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Standarti">
              <contractstandard ref="contractstandard" @update="loadData(data.contract.id)"/>
              <b-btn variant="primary" class="mr-2" size="sm" @click="$refs.contractstandard.loadData( data.contract.id, 0)" :disabled="data.contract.id === 0">I ri</b-btn>
              <b-btn variant="info" size="sm" @click="calculateMandays" :disabled="data.contract.id === 0">llogarit dite/njerez</b-btn>
              <b-table no-border-collapse :sticky-header="(winHeight - 205) / 3 + 'px'" class="mt-3" :items="data.standards" :fields="fields.standards" @row-clicked="$refs.contractstandard.loadData( data.contract.id, $event.id)" :tbody-tr-class="rowClassStandards"/>
            </b-form-group>
            <b-form-group label="Note">
              <b-form-textarea v-model="data.contract.note" rows="2"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Adresa">
            <contractsite ref="site" @update="loadData(data.contract.id)"/>
            <b-btn variant="primary" class="mr-2" size="sm" @click="$refs.site.loadData(data.contract.id, 0)">I ri</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 335)+ 'px'" class="mt-3" :items="data.sites" :fields="fields.sites" @row-clicked="$refs.site.loadData(data.contract.id, $event.id)"/>
          </b-tab>
          <b-tab title="Fusha">
            <contractscope ref="scope" @update="loadData(data.contract.id)"/>
            <b-btn variant="primary" size="sm" class="mr-2" @click="$refs.scope.loadData(data.contract.id, 0)">I ri</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 335) + 'px'" class="mt-3" :items="data.scopes" :fields="fields.scopes" @row-clicked="$refs.scope.loadData(data.contract.id, $event.id)"/>
          </b-tab>
          <b-tab title="Parametra">
            <contractparameter ref="parameter" @update="loadData(data.contract.id)"/>
            <b-table no-border-collapse :sticky-header="(winHeight - 335) + 'px'" :items="data.parameters" :fields="fields.parameters" @row-clicked="$refs.parameter.loadData( data.contract.id, $event.id)"/>
          </b-tab>
          <b-tab title="Cmimi">
            <contractcost ref="cost" @update="loadData(data.contract.id)"/>
            <b-btn variant="primary" size="sm" @click="$refs.cost.loadData(data.contract.id, 0)">I ri</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 335) + 'px'" class="mt-3" :items="data.costs" :fields="fields.costs" @row-clicked="$refs.cost.loadData(data.contract.id, $event.id)"/>
          </b-tab>
          <b-tab>
            <template #title>
              <h5 v-if="data.audits.length === 0"><b-badge variant="danger">Programi i auditimit Completare!</b-badge></h5><span v-else>Programi i auditimit</span>
            </template>
            <audit ref="audit" @update="loadData(data.contract.id)"/>
            <b-btn variant="info" size="sm" v-show="data.audits.length === 0" @click="prepareAudits">Gjeneron</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 335) + 'px'" class="mt-3" :items="data.audits" :fields="fields.audits" @row-clicked="$refs.audit.loadData($event.id)">
              <template v-slot:cell(auditsstatus_id)="row">
                <b-form-select v-model="data.contract.audits.find(x => x.id === row.item.id).auditsstatus_id" :options="data.auditsstatus" value-field="id" text-field="val" size="sm"/>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Certifikata" :disabled="data.contract.id === 0">
            <b-table no-border-collapse :sticky-header="(winHeight - 335) + 'px'" class="mt-3" :items="data.standards" :fields="fields.certificates"/>
          </b-tab>
          <b-tab title="Dokumenta">
            <contractdocument ref="document" @update="loadData(data.contract.id)"/>
            <b-btn variant="info" class="mr-2" size="sm" @click="prepareDocuments">Gjeneron</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 205)+ 'px'" class="mt-3" :items="data.documents" :fields="fields.documents" @row-clicked="this.$refs.document.loadData(data.contract.id, $event.id)">
              <template v-slot:cell(id)="row">
                <b-btn variant="primary" size="sm" @click="$refs.document.downloadDocument(row.item.id)">Shkarko</b-btn>
              </template>            
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="loadData(data.contract.id)">Përditëso</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData">Ruaj</b-btn>
          <b-btn variant="danger" v-show="data.contract.id > 0" @click="deleteData">Fshije</b-btn>

        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import contractstandard from './contractstandard.vue';
import contractsite from './contractsite.vue';
import contractscope from './contractscope.vue';
import contractcost from './contractcost.vue';
import contractparameter from './contractparameter.vue';
import contractdocument from './contractdocument.vue';

export default {
  name: "contract",
  components: { contractstandard, contractsite, contractscope, contractcost, contractparameter, contractdocument },
  data() {
    return {
      show: true,
      data: null,
      showMail: false,
      fields: {
        standards: [{
          key: 'standard',
          label: "Norma",
        },{
          key: 'iafcodes',
          label: 'IAF',
        },{
          key: 'riskslevel',
          label: 'Risku',
        },{
          key: 'accreditated',
          label: 'Akredituar',
          sortable: true,
          formatter: value => {
            return value === true ? 'Po' : 'Nr';
          },
        },{
          key: 'mandaystiletable',
          label: 'Tabela d/nj',
        },{
          key: 'mandaysreduced',
          label: "D/nj Zvogeluar",
        },{
          key: 'excludedpoints',
          label: 'Perjashtime',
        },{
          key: 'note',
          label: 'Shenime',
        }],
        sites: [{
          key: 'address',
          label: "Adresë",
        },{
          key: 'city',
          label: 'Qyteti',
          sortable: true,
        },{
          key: 'postalcode',
          label: 'Kodi Postar',
          sortable: true,
        },{
          key: 'region',
          label: "Rajon",
          sortable: true,
        },{
          key: "state",
          label: "Shtetit",
          sortable: true,
        },{
          key: 'active',
          label: 'Aktive',
          sortable: true,
          formatter: value => {
            return value === true ? 'Po' : 'Nr';
          },
        },{
          key: 'registered',
          label: 'Regjistruar',
          sortable: true,
          formatter: value => {
            return value === true ? 'Po' : 'Nr';
          },
        },{
          key: 'iafcodes',
          label: 'Kodet IAF'
        },{
          key: "totalworkers",
          label: "Totali i punëtorëve",
        },{
          key: "effectiveworkers",
          label: "Punëtorët efektivë",
        },{
          key: "subcontractorsworkers",
          label: "Punëtorët e nënkontraktorëve",
        },{
          key: "shiftsworkers",
          label: "Turne",
        },{
          key: "processesonsite",
          label: "Proceset në vend",
        },{
          key: "processessubcontractors",
          label: "Aktivitet i nenkontraktorve",
        }],
        scopes: [{
          key: 'lang',
          label: 'Gjuha',
        },{
          key: 'scope',
          label: 'Fushëveprimi',
        }],
        costs: [{
          key: 'year',
          label: 'Viti',
        },{
          key: 'phase',
          label: 'Faza',
        },{
          key: 'cost',
          label: 'Çmimi (€)',
        },{
          key: 'mandays',
          label: 'Ditët e mashkullit',
        }],
        parameters: [{
          key: 'type',
          label: 'Djalë',
        },{
          key: 'val',
          label: 'Vlera',
          formatter: (value, key, item) => {
            if ( item.format === 'date' ){
              if (value == null) return null;
              var d = new Date(value);
              return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
            }
            if ( item.format === 'bool' && value !== null ) return value === "true" ? 'Po' : 'Nr';
            return value;
          }
        }],
        audits: [{
          key: "plandate",
          label: 'Data e planit',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'expiracy',
          label: 'Afati i fundit',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'auditsstatus_id',
          label: 'Statusi',
        },{
          key: 'standardsphases',
          label: 'Standardet'
        },{
          key: 'points',
          label: 'Pika standarde'
        },{
          key: 'veto',
          label: 'Komiteti teknik',
        }],
        certificates: [{
          key: 'standard',
          label: "Standard",
        },{
          key: 'certificatecode',
          label: 'Certifikata',
        },{
          key: 'certificatefirstissue',
          label: 'Numri 1',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'certificateissue',
          label: 'Çështje',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'certificatevalidfrom',
          label: 'Vlefshme nga',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'certificateexpirancy',
          label: 'Afati i fundit',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        }],
        documents: [{
          key: "filename",
          label: "Skedari",
          sortable: true,
        },{
          key: "datetime",
          label: "Përditëso",
          formatter: value => {
            let d = new Date(value);
            return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':') + " " + [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          sortable: true,
        },{
          key: "id",
          label: "",
        }],
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    rowClassStandards(item, type){
      if (!item || type !== 'row') return '';
      return item.active === false ? 'table-danger' : "";
    },
    loadData( id ){
      if ( this.data === null ) this.show = false;
      this.openHttp("contracts_contract", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    calculateMandays(){
      this.saveHttp("contracts_calculate", { id: this.data.contract.id }).then((result) => {
        if ( result.result == true ) this.loadData( this.data.contract.id );
      })
    },
    prepareAudits(){
      this.saveHttp("contracts_auditprepare", { id: this.data.contract.id }).then((result) => {
        if ( result.result == true ) this.loadData( this.data.contract.id );
      })
    },
    prepareDocuments(){
      this.saveHttp("contracts_documentprepare", { id: this.data.contract.id }).then((result) => {
        if ( result.result == true ) this.loadData( this.data.contract.id );
      })
    },
    saveData(){
      this.saveHttp("contracts_contract_save", this.data.contract).then((result) => {
        if ( result.result == true ) this.loadData(result.id);
      })
    },
    deleteData(){
      this.deleteHttp("contracts_contract_delete", { id: this.data.contract.id }).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>
<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Mundësi" scrollable @hidden="closeForm" @close="closeForm">
      <b-form-group label="Përshkrim">
        <b-form-textarea size="sm" v-model="data.opportunity.opportunity" rows="5"/>
      </b-form-group>
      <b-form-checkbox-group v-model="data.opportunity.standardspoints">
        <b-form-group v-for="item in data.standards" :key="item.standard" :label="item.standard">
          <b-form-checkbox v-for="item2 in item.standardspoints" :key="item2.id" :value="item2.id">{{ item2.val }}</b-form-checkbox>
        </b-form-group>
      </b-form-checkbox-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Ruaj</b-btn>
          <b-btn variant="danger" v-show="data.opportunity.id > 0" @click="deleteData">Fshije</b-btn>
        </div>
      </template>           
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "checklistopportunity",
  data() {
    return {
      show: false,
      data: null
    }
  },
  methods: {
    loadData( id, auditsId ){
      this.openHttp("checklists_opportunity", { id: id, audits_id: auditsId }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("checklists_opportunity_save", this.data.opportunity).then((result) => {
        if ( result.result === true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("checklists_opportunity_delete", { id: this.data.opportunity.id } ).then((result) => {
        if ( result.result === true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>
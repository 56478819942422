import Vue from 'vue'
import Router from 'vue-router'
import homepage from '@/components/homepage/homepage.vue'

import companies from '@/components/companies/companies.vue'
import offers from '@/components/offers/offers.vue'
import contracts from '@/components/contracts/contracts.vue'
import plans from '@/components/plans/plans.vue'
import checklists from '@/components/checklists/checklists.vue'
import vetos from '@/components/vetos/vetos.vue'
import certificates from '@/components/certificates/certificates.vue'
import curricula from '@/components/curricula/curricula.vue'
import qualifications from '@/components/qualifications/qualifications.vue'
import standards from '@/components/standards/standards.vue'
import kpi from '@/components/kpi/kpi.vue'
import users from '@/components/users/users.vue'
import login from '@/components/login/login.vue'
import axios from 'axios';
import store from '../store';
import mixin from '@/mixins/index'

Vue.use(Router)

Vue.mixin(mixin);

const router = new Router  ({
  routes:[{
    path: '/homepage',
    name: 'homepage',
    component: homepage,
    meta: {
      title: '',
      requiresAuth: true,
    },
  },{
    path: '/companies',
    name: 'companies',
    component: companies,
    meta: {
      title: 'Kompani',
      requiresAuth: true,
    },
  },{
    path: '/offers',
    name: 'offers',
    component: offers,
    meta: {
      title: 'Kërkesa për kuotim',
      requiresAuth: true,
    },
  },{
    path: '/contracts',
    name: 'contracts',
    component: contracts,
    meta: {
      title: 'Kontrata',
      requiresAuth: true,
    },
  },{
    path: '/plans',
    name: 'plans',
    component: plans,
    meta: {
      title: 'Plans',
      requiresAuth: true,
    },
  },{
    path: '/checklists',
    name: 'checklists',
    component: checklists,
    meta: {
      title: 'Checklists',
      requiresAuth: true,
    },
  },{
    path: '/vetos',
    name: 'vetos',
    component: vetos,
    meta: {
      title: 'vetos',
      requiresAuth: true,
    },
  },{
    path: '/certificates',
    name: 'certificates',
    component: certificates,
    meta: {
      title: 'Certifikatat',
      requiresAuth: true,
    },
  },{
    path: '/curricula',
    name: 'curricula',
    component: curricula,
    meta: {
      title: 'Curricula',
      requiresAuth: true,
    },
  },{
    path: '/qualifications',
    name: 'qualifications',
    component: qualifications,
    meta: {
      title: 'Qualifiche',
      requiresAuth: true,
    },
  },{
    path: '/standards',
    name: 'standards',
    component: standards,
    meta: {
      title: 'Standards',
      requiresAuth: true,
    },
  },{
    path: '/kpi',
    name: 'kpi',
    component: kpi,
    meta: {
      title: 'KPI',
      requiresAuth: true,
    },
  },{
    path: '/users',
    name: 'users',
    component: users,
    meta: {
      title: 'Utenti',
      requiresAuth: true,
    },
  },{
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      title: 'Login',
      requiresAuth: false,
    },
  },{
    path: "*",
    redirect: '/homepage',
  }]
})

router.beforeEach((to, from, next) => {
  document.title = 'AQS' + ( to.meta.title !== '' ? ' - ' + to.meta.title : '');
  if (to.name === "login") store.commit('unSetToken');
  if (to.meta.requiresAuth === true) {
    if ( store.getters.isLogged === true ){
      let promise = axios.post('api.php?routes_check', { token: store.state.token });
      return promise.then((data) => {
        if ( data.data.result === true ){
          store.commit('setRoutes', data.data.allowed);
        } else {
          store.commit('unSetToken');
          router.push('login');
        }
        if ( store.getters.getRoutes.indexOf(to.name) > -1 ) next();
        return(true)
      }).catch(error => {
        console.log(error);
        return []
      })
    } else {
      router.push('login');
    }
  } else {
    next();
  }
})

export default router;
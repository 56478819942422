import axios from "axios";

export default {
    methods: {
        loginHttp ( usr, psw ){
            let self = this;
            return new Promise(resolve => {
                axios.post("api.php?login_login", { usr: usr, psw: psw } )
                .then(function (response) {
                    if (response.data.token !== null) {
                        self.$store.commit('setToken', response.data.token);
                        self.$store.commit('setLang', response.data.lang);
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
                .catch(function (error) {
                    self.$bvToast.toast(error, { title: "AQS - Error", variant: "warning", appendToast: true });
                });
            });
        },
        openHttp (functionName, parameters){
            let self = this;
            let dataHttp = {
                token: this.$store.getters.getToken
            };
            if ( parameters !== null ) dataHttp.data = parameters;
            return new Promise(resolve => {
                axios.post("api.php?" +  functionName, dataHttp )
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    self.$bvToast.toast(error, { title: "AQS - Error", variant: "warning", appendToast: true });
                });
            });
        },
        saveHttp (functionName, parameters){
            let self = this;
            return new Promise(resolve => {
                axios.post( 'api.php?' +  functionName, { token: this.$store.getters.getToken, data: parameters } )
                .then(function (response) {
                    self.$bvToast.toast("U ruajt me sukses", { title: "AQS", variant: "success", autoHideDelay: 2000, appendToast: true })
                    resolve(response.data);
                })
                .catch(function (error) {
                    self.$bvToast.toast(error, { title: "AQS - Error", variant: "warning", appendToast: true });
                });
            });
        },
        deleteHttp (functionName, parameters){
            let self = this;
            return new Promise(resolve => {
                axios.post('api.php?' +  functionName, { token: this.$store.getters.getToken, data: parameters } )
                .then(function (response) {
                    self.$bvToast.toast("U fshi me sukses", { title: "AQS", variant: "danger", autoHideDelay: 2000, appendToast: true })
                    resolve(response.data);
                })
                .catch(function (error) {
                    self.$bvToast.toast(error, { title: "AQS - Error", variant: "warning", appendToast: true })
                });
            });
        },
        createForm(url, data){
            return url + data
        },
        addNullCombo( fromData, label = "Të gjitha"){
            return [{ id: null, val: label }].concat(fromData);
        },
        convertCombo( fromData, fromValues ){
            return fromValues.map(x => fromData.find(y => y.id === x) );
        },
        updateCombo( fromValues ){
            this.$forceUpdate();
            return fromValues.map(x => x.id);
        },
        convertComboSimple( fromData, fromValue ){
          return fromData.find(x => x.id === fromValue);
        },
        updateComboSimple( fromValue ){
            this.$forceUpdate();
            return fromValue != null ? fromValue.id : null;
        }
    }
}

<template>
    <div>
        <b-modal v-if="data!== null" v-model="show" :title="'Data e auditimit: ' + data.user + ' ' + data.date" size="lg">
            <b-table responsive :items="data.usersstandardsphasesrolesdates" :fields="fields">
                <template v-slot:cell(usersroles_id)="row">
                    <b-form-select v-model="row.item.usersroles_id" :options="data.usersroles" size="sm" value-field="id" text-field="val"/>
                </template>
                <template v-slot:cell(manday)="row">
                    <b-form-input size="sm" type="number" v-model="row.item.manday" step="0.00001" min="0" :disabled="row.item.usersroles_id === null"/>
                </template>
                <template v-slot:cell(reset)="row">
                    <b-btn variant="warning" size="sm" @click="row.item.manday = null; row.item.usersroles_id = null" v-show="row.item.usersroles_id > 0">Reset</b-btn>
                </template>
            </b-table>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" @click="saveData">Ruaj</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "plancalendardate",
    data() {
        return {
            data: null,
            show: true,
            fields: [{
                key: "standard",
                label: "Norma",
            },{
                key: "phase",
                label: "Faza"
            },{
                key: "usersroles_id",
                label: "Ruoli",
            },{
                key: "manday",
                label: "GG/uomo",
            },{
                key: "reset",
                label: "",
            }]
        }
    },
    methods: {
        cleanDate(date){
            if ( date == null ) return null;
            var d = new Date( date );
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map( n => n < 10 ? `0${n}` : `${n}` ).join('-');
        },
        loadData( auditsId, usersId, date ){
            this.openHttp("plans_userroledate", { audits_id: auditsId, users_id: usersId, date: date } ).then((result) => {
                this.data = result
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("plans_userroledate_save", this.data.usersstandardsphasesrolesdates ).then((result) => {
                if ( result.result === true ){
                    this.$emit("update");
                    this.show = false;
                }
            })
        },
    },
}
</script>
<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" title="Fushëveprimi" @hidden="closeForm" @close="closeForm">
            <b-form-group label="Gjuha">
                <b-form-select v-model="data.scope.languages_id" :options="data.languages" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Gjuha e përdorur?">
                <b-form-select v-model="data.scope.auditlang" :options="comboAuditlang" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Fushëveprimi">
                <b-form-textarea v-model="data.scope.scope" rows="4"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Ruaj</b-btn>
                    <b-btn variant="danger" v-show="data.scope.languages_id > 0" @click="deleteData">Fshije</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "contractscope",
    data() {
        return {
            show: false,
            data: null,
            comboAuditlang: [{
                id: false,
                val: "I pa perdorur",
            },{
                id: true,
                val: "I perdorur",
            }]
        }
    },
    methods: {
        loadData( contractsId, id ){
            this.show = false;
            this.openHttp("contracts_scope", { contracts_id: contractsId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("contracts_scope_save", this.data.scope ).then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("contracts_scope_delete", { languages_id: this.data.scope.languages_id, contracts_id: this.data.scope.contracts_id }).then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>
<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" title="Risk level" @hidden="closeForm" @close="closeForm">
            <b-form-group label="Standard">
                <b-form-select v-model="data.standardsiafcodesrisklevel.standards_id" :options="data.standards" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="IAF">
                <b-form-select v-model="data.standardsiafcodesrisklevel.iafcodes_id" :options="data.iafcodes" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Risk level">
                <b-form-select v-model="data.standardsiafcodesrisklevel.riskslevels_id" :options="data.riskslevels" value-field="id" text-field="val"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" @click="saveData">Salva</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "standardsiafcodesrisklevel",
    data() {
        return {
            show: false,
            data: null,
        }
    },
    computed: {
        winHeight (){
            return this.$store.getters.getWinHeight;
        },
    },
    methods: {
        loadData( standardsId, iafcodesId ){
            this.openHttp("standards_standardsiafcodesrisklevel", { standards_id: standardsId, iafcodes_id: iafcodesId }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("standards_standardsiafcodesrisklevel_save", this.data.standardsiafcodesrisklevel, "standardsiafcodesrisklevel salvato!" ).then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>
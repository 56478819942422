<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Kualifikimi" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-row>
        <b-col lg="4">
          <b-form-group label="Personi">
            <b-form-select v-model="data.qualification.users_id" :options="data.users" value-field="id" text-field="val" :disabled="data.qualification.id > 0"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Standarde">
            <b-form-select v-model="data.qualification.standards_id" :options="data.standards" value-field="id" text-field="val" :disabled="data.qualification.id > 0"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Roli">
            <b-form-select v-model="data.qualification.usersroles_id" :options="data.usersroles" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Kodet Iaf" v-show="data.usersroles.find(x => x.id === data.qualification.usersroles_id).need">
        <b-form-checkbox-group v-model="data.qualification.iafcodes">
          <b-form-checkbox v-for="item in data.iafcodes.filter(x => x.standards.includes(data.qualification.standards_id))" :key="item.id" :value="item.id">{{ item.val }}</b-form-checkbox>
        </b-form-checkbox-group>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Ruaj</b-btn>
          <b-btn variant="warning" v-show="data.qualification.id > 0" class="mr-2" @click="data.qualification.id = 0">Dublikatë</b-btn>
          <b-btn variant="danger" v-show="data.qualification.id > 0" @click="deleteData">Fshije</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "qualification",
  data() {
    return {
      show: false,
      data: null,
    }
  },
  methods: {
    loadData( id ){
      this.openHttp("qualifications_qualification", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("qualifications_qualification_save", this.data.qualification).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("qualifications_qualification_delete", { id: this.data.qualification.id }).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if (reload === true) this.$emit('update');
    },
  },
}
</script>
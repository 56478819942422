<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" title="konfirmimin e ofertës" @hidden="closeForm" @close="closeForm">
            <b-form-group label="kontratës">
                <b-form-input v-model="data.offer.contract"/>
            </b-form-group>
            <b-form-group label="data e konfirmimit të kontratës">
                <b-form-input type="date" v-model="data.offer.contract_date"/>
            </b-form-group>
            <b-form-group label="data e skadimit të kontratës">
                <b-form-input type="date" v-model="data.offer.expiry_date"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" @click="saveData">Ruaj</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "offerconfirmation",
    data() {
        return {
            show: false,
            data: null
        }
    },
    methods: {
        loadData( id ){
            this.show = false;
            this.openHttp("offers_confirmation", { id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("offers_confirmation_save", this.data.offer).then((result) => {
                if (result.result == true) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>
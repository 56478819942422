<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Supplier" @hidden="closeForm" @close="closeForm">
      <b-form-group label="Supplier">
        <b-form-input v-model="data.supplier.supplier"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
          <b-btn variant="danger" v-show="data.supplier.id > 0" @click="deleteData">Delete</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "supplier",
  data() {
    return {
      data: null,
      show: false
    }
  },
  methods: {
    loadData( id ){      
      if ( this.data === null ) this.show = false;
      this.openHttp("users_supplier", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("users_supplier_save", this.data.supplier, "Supplier saved!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("users_supplier_delete", { id: this.data.supplier.id }, "Supplier deleted!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  }
}
</script>
<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Curriculum" @hidden="closeForm()" @close="closeForm()">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Anagrafica" active>
            <b-row>
              <b-col lg="6">
                <b-form-group label="Fullname">
                  <b-form-input v-model="data.curriculum.fullname" disabled/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Luogo di nascita">
                  <b-form-input v-model="data.curriculum.birthplace"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Data di nascita">
                  <b-form-input v-model="data.curriculum.birthdate" type="date"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <b-form-group label="Codice fiscale">
                  <b-form-input v-model="data.curriculum.taxcode"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Documento">
                  <b-form-input v-model="data.curriculum.personalid"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Residenza">
                  <b-form-input v-model="data.curriculum.address"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <b-form-group label="Telefono">
                  <b-form-input v-model="data.curriculum.phone"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Mail">
                  <b-form-input v-model="data.curriculum.mail"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <b-form-group label="Aggiornamento">
                  <b-form-input v-model="data.curriculum.lastupdatecv" type="date"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab :title="'Attestati - ' + data.certificates.length">
            <curriculumcertificate ref="certificate" @update="loadData(data.curriculum.id)"/>
            <b-btn variant="primary" size="sm" @click="$refs.certificate.loadData(data.curriculum.id, 0)">Nuovo</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 390) + 'px'" responsive class="mt-3" :items="data.certificates" :fields="fields.certificates" @row-clicked="$refs.certificate.loadData(data.curriculum.id, $event.id)"/>
          </b-tab>
          <b-tab :title="'Educazione - ' + data.education.length">
            <curriculumeducation ref="education" @update="loadData(data.curriculum.id)"/>
            <b-btn variant="primary" size="sm" @click="$refs.education.loadData(data.curriculum.id, 0)">Nuovo</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 390) + 'px'" responsive class="mt-3" :items="data.education" :fields="fields.education" @row-clicked="$refs.education.loadData(data.curriculum.id, $event.id)"/>
          </b-tab>
          <b-tab :title="'Esperienze lavorative - ' + data.experiences.length">
            <curriculumexperience ref="experience" @update="loadData(data.curriculum.id)"/>
            <b-btn variant="primary" size="sm" @click="$refs.experience.loadData(data.curriculum.id, 0)">Nuovo</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 390) + 'px'" responsive class="mt-3" :items="data.experiences" :fields="fields.experiences" @row-clicked="$refs.experience.loadData(data.curriculum.id, $event.id)"/>
          </b-tab>
          <b-tab :title="'Consulenze - ' + data.consultancies.length">
            <curriculumconsultancy ref="consultancy" @update="loadData(data.curriculum.id)"/>
            <b-btn variant="primary" size="sm" @click="$refs.consultancy.loadData(data.curriculum.id, 0)">Nuovo</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 390) + 'px'" responsive class="mt-3" :items="data.consultancies" :fields="fields.consultancies" @row-clicked="$refs.consultancy.loadData(data.curriculum.id, $event.id)"/>
          </b-tab>
          <b-tab :title="'Audit logs - ' + data.auditlogs.length">
            <curriculumauditlog ref="auditlog" @update="loadData(data.curriculum.id)"/>
            <b-btn variant="primary" size="sm" @click="$refs.auditlog.loadData(data.curriculum.id, 0)">Nuovo</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 390) + 'px'" responsive class="mt-3" :items="data.auditlogs" :fields="fields.auditlogs" @row-clicked="$refs.auditlog.loadData(data.curriculum.id, $event.id)"/>
          </b-tab>
          <b-tab :title="'Documenti - ' + data.documents.length">
            <curriculumdocument ref="document" @update="loadData(data.curriculum.id)"/>
            <b-btn variant="info" class="mr-2" size="sm" @click="prepareDocuments">Genera</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 390) + 'px'" :tbody-tr-class="rowClassDocuments" responsive class="mt-3" :items="data.documents" :fields="fields.documents" @row-clicked="$refs.document.loadData('users', data.curriculum.id, $event.id)">
              <template v-slot:cell(id)="row">
                <b-btn variant="primary" size="sm" v-if="row.item.datetime !== null" @click="$refs.document.download_file(row.item.id)">Scarica</b-btn>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" v-show="data.curriculum.id > 0" class="mr-2" @click="loadData(data.curriculum.id)">Aggiorna</b-btn>
          <b-btn variant="success" @click="saveData">Salva</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import curriculumcertificate from './curriculacomponents/curriculumcertificate.vue';
import curriculumexperience from './curriculacomponents/curriculumexperience.vue';
import curriculumeducation from './curriculacomponents/curriculumeducation.vue';
import curriculumconsultancy from './curriculacomponents/curriculumconsultancy.vue';
import curriculumauditlog from './curriculacomponents/curriculumauditlog.vue';
import curriculumdocument from './curriculacomponents/curriculumdocument.vue';

export default {
  name: "curriculum",
  components: { curriculumcertificate, curriculumexperience, curriculumeducation, curriculumconsultancy, curriculumauditlog, curriculumdocument },
  data() {
    return {
      show: false,
      data: null,
      fields: {
        certificates: [{
          key: 'fromdate',
          label: 'Da',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }        
        },{
          key: 'todate',
          label: 'A',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'degree',
          label: 'Certificato / Attestato'
        },{
          key: 'issuer',
          label: 'Rilasciato da'
        },{
          key: 'duration',
          label: 'Ore',
        },{
          key: 'qualify40',
          label: '40h',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            return value === true ? "Si" : "No";
          }
        },{
          key: "standards",
          label: "Norme",
        },{
          key: 'iafs',
          label: 'IAF',
        }],
        education: [{
          key: 'fromdate',
          label: 'Da',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }        
        },{
          key: 'todate',
          label: 'A',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'school',
          label: 'Scuola',
        },{
          key: 'discipline',
          label: 'Disciplina'
        },{
          key: 'title',
          label: 'Titolo',
        },{
          key: "standards",
          label: "Norme",
        },{
          key: 'iafs',
          label: 'IAF',
        }],
        experiences: [{
          key: 'fromdate',
          label: 'Da',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }        
        },{
          key: 'todate',
          label: 'A',
          sortable: true,
          formatter: value => {
            if (value == null) return 'In Corso';
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'company',
          label: 'Azienda',
          sortable: true
        },{
          key: 'sector',
          label: 'Settore'
        },{
          key: "responsibilities",
          label: "Responsabilità"
        },{
          key: "standards",
          label: "Norme",
        },{
          key: 'iafs',
          label: 'IAF',
        }],
        consultancies: [{
          key: "fromdate",
          label: "Da",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }        
        },{
          key: "todate",
          label: "A",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "company",
          label: "Azienda",
          sortable: true,
        },{
          key: 'sector',
          label: 'Settore',
        },{
          key: 'daysdone',
          label: 'Giornate',
          sortable: true,
        },{
          key: 'responsibilities',
          label: 'Responsibilities',
        },{
          key: "standards",
          label: "Norme",
        },{
          key: 'iafs',
          label: 'IAF',
        }],
        auditlogs: [{
          key: 'datereport',
          label: 'Data',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'company',
          label: 'Azienda',
        },{
          key: 'certificationbody',
          label: 'Ente',
        },{
          key: 'manday',
          label: 'gg',
        },{
          key: 'usersrole',
          label: 'Ruolo',
        },{
          key: 'standards',
          label: 'Norme',
        },{
          key: 'phase',
          label: 'Fase',
        }],
        documents: [{
          key: 'filename',
          label: 'File',
          sortable: true,
        },{
          key: 'datetime',
          label: 'Aggiornamento',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-') + " " + [d.getHours(), d.getMinutes(), d.getSeconds()].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
          }
        },{
          key: "id",
          label: "",
        }]
      },
    }
  },
  computed: {
    winHeight(){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    rowClassDocuments(item) {
      return item !== null && item.datetime === null ? "table-danger" : "";
    },
    loadData(id){
      this.openHttp("curricula_curriculum", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    prepareDocuments(){
      this.saveHttp("curricula_document_generate", { id: this.data.curriculum.id }, "Documenti generati!" ).then((result) => {
        if ( result.result === true ) this.loadData( this.data.curriculum.id );
      })
    },
    saveData(){
      this.saveHttp("curricula_curriculum_save", this.data.curriculum, "Curriculum salvato!" ).then((result) => {
        if ( result.result === true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit("update");
    }
  },
}
</script>
<template>
    <div>
        <b-modal v-if="show" v-model="show" title="Norma" size="lg" @hidden="closeForm" @close="closeForm">
            <b-row>
                <b-col lg="6">
                    <b-form-group label="Norma">
                        <b-form-select v-model="data.standard.standards_id" :options="data.standards" value-field="id" text-field="val" @change="data.standard.points = []"/>
                    </b-form-group>
                </b-col>
                <b-col lg="3">
                    <b-form-group label="Shtetit">
                        <b-form-select v-model="data.standard.active" :options="activeCombo" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="3">
                    <b-form-group label="Rreziku">
                        <b-form-select v-model="data.standard.riskslevels_id" :options="data.riskslevels" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-form-group label="Përjashtimet" v-show="data.points.findIndex(x => x.standards_id === data.standard.standards_id) > -1">
                <b-form-checkbox-group v-model="data.standard.points">
                    <b-form-checkbox v-for="(item, index) in data.points.filter(x => x.standards_id === data.standard.standards_id)" :key="index" :value="item.id">{{ item.val }}</b-form-checkbox>
                </b-form-checkbox-group>
            </b-form-group>
            <b-row>
                <b-col lg="3">
                    <b-form-group label="Integrimi i sistemit">
                        <b-form-select v-model="data.standard.integrationsystem" :options="integrationCombo" :disabled="data.standardscount <= 1" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="3">
                    <b-form-group label="Integrimi i ekipit të auditimit">
                        <b-form-select v-model="data.standard.integrationauditteam" :options="integrationCombo" :disabled="data.standardscount <= 1" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="3">
                    <b-form-group label="Gg / njeri tavolinë">
                        <b-form-input v-model="data.standard.mandaystiletable" type="number" min="0" step="0.0001"/>
                    </b-form-group>
                </b-col>
                <b-col lg="3">
                    <b-form-group label="Njeri i reduktuar / ditë">
                        <b-form-input v-model="data.standard.mandaysreduced" type="number" min="0" step="0.0001"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-form-group label="Shënim">
                <b-form-textarea v-model="data.standard.notecalc" rows="2"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Ruaj</b-btn>
                    <b-btn variant="danger" v-show="data.standard.standards_id > 0" @click="deleteData">Fshije</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "contractstandard",
    data() {
        return {
            show: false,
            data: null,
            activeCombo: [{
                id: true,
                val: 'Aktiv',
            },{
                id: false,
                val: 'Joaktiv',
            }],
            integrationCombo: [{
                id: 0,
                val: '0%'
            },{
                id: 20,
                val: '20%'
            },{
                id: 40,
                val: '40%'
            },{
                id: 60,
                val: '60%'
            },{
                id: 80,
                val: '80%'
            },{
                id: 100,
                val: '100%'
            }]
        }
    },
    methods: {
        loadData( contractsId, id ){
            this.show = false;
            this.openHttp("contracts_standard", { contracts_id: contractsId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("contracts_standard_save", this.data.standard).then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("contracts_standard_delete", { standards_id: this.data.standard.standards_id, contracts_id: this.data.standard.contracts_id }).then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>
<template>
  <div>
    <contract ref="contract" @update="loadData(true)"/>
    <b-modal v-if="showFilter" v-model="showFilter" title="Filtro">
      <b-form-group label="Kontrata">
        <b-form-input v-model="tempFilter.contract"/>
      </b-form-group>
      <b-form-group label="Shtetit">
        <b-form-select v-model="tempFilter.active" :options="comboActive" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Agjencia">
        <b-form-input v-model="tempFilter.company"/>
      </b-form-group>
      <b-form-group label="Norma">
        <b-form-select v-model="tempFilter.standards_id" :options="addNullCombo(filtersMenu.standards)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Tipologji">
        <b-form-select v-model="tempFilter.contractstypes_id" :options="addNullCombo(filtersMenu.contractstypes)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="dhënë nga">
        <b-form-input v-model="tempFilter.contract_date_from" type="date"/>
      </b-form-group>
      <b-form-group label="datë në">
        <b-form-input v-model="tempFilter.contract_date_to" type="date"/>
      </b-form-group>
      <b-form-group label="qytet">
        <b-form-input v-model="tempFilter.city"/>
      </b-form-group>
      <b-form-group label="Shtetëror">
        <b-form-select v-model="tempFilter.states_id" :options="addNullCombo(filtersMenu.states)" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="filterSet">Kerko</b-btn>
          <b-btn variant="warning" @click="filterReset">Rifillo</b-btn>
        </div>
      </template>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData">Rifresko</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpen">Filtro</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sort.by" :sort-desc.sync="search.sort.desc" @sort-changed="sortingChange" @row-clicked="$refs.contract.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Nuk ka rezultat</template>
    </b-table>
    <tablebottom :busy="isBusy" :currentpage="search.page" :total="items.total" @updateCurrentPage="search.page = $event" @loadData="loadData"/>
  </div>
</template>

<script>
export default {
  name: "contracts",
  data() {
    return {
      isBusy: true,
      showFilter: false,
      comboActive: [{
        id: true,
        val: "Aktive"
      },{
        id: false,
        val: "Joaktiv"
      }],
      search: {
        page: 1,
        filters: {
          contract_date_from: null,
          contract_date_to: null,
          active: true,
          contract: null,
          company: null,
          standards_id: null,
          contractstypes_id: null,
          city: null,
          states_id: null
        },
        sort: {
          by: 'expiracydate',
          desc: false,
        },
      },
      tempFilter: {
        contract_date_from: null,
        contract_date_to: null,
        active: true,
        contract: null,
        company: null,
        standards_id: null,
        contractstypes_id: null,
        city: null,
        states_id: null
      },
      filtersMenu: {
        companies:[],
        standards:[],
        contractstypes: [],
        states: []
      },
      fields: [{
        key: 'company',
        label: 'Agjencia',
        sortable: true,
      },{
        key: 'contract',
        label: 'Kontrata',
        sortable: true,
      },{
        key: 'standards',
        label: 'Standardet',
      },{
        key: 'create_date',
        label: 'Data e aplikimi',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        },
      },{
        key: 'contract_date',
        label: 'Data e kontrates',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        },
      },{
        key: 'expiry_date',
        label: 'Data e skadences',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        },
      },{
        key: 'closure_date',
        label: 'Data e mbylljes',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        },
      }],
      items: {
        total: 0,
        data: [],
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData( notScroll = false ){
      if (notScroll == false) this.isBusy = true;
      this.openHttp("contracts_contracts", this.search ).then((result) => {
        this.isBusy = false;
        this.items = result;
      })
    },
    filterOpen(){
      this.openHttp("contracts_contracts_filters", null ).then((result) => {
        this.filtersMenu = result;
        this.showFilter = true;
      })
    },
    filterSet(){
      this.showFilter = false;
      this.search.page = 1;
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        contract_date_from: null,
        contract_date_to: null,
        active: true,
        contract: null,
        company: null,
        standards_id: null,
        contractstypes_id: null,
        city: null,
        states_id: null,
      };
      this.filterSet();
    },
    sortingChange(ctx) {
      this.search.sort.by = ctx.sortBy;
      this.search.sort.desc = ctx.sortDesc;
      this.loadData();
    }
  }
}
</script>

<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" title="Çmimi i vitit" @hidden="closeForm" @close="closeForm">
            <b-form-group label="Viti">
                <b-form-input v-model="data.cost.year" type="number" min="0" step="1"/>
            </b-form-group>
            <b-form-group label="Faza">
                <b-form-select v-model="data.cost.contractsphases_id" :options="data.contractsphases" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Çmimi">
                <b-form-input v-model="data.cost.cost" type="number" min="0" step="0.01"/>
            </b-form-group>
            <b-form-group label="Ditët e mashkullit">
                <b-form-input v-model="data.cost.mandays" type="number" min="0" step="0.0001"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Ruaj</b-btn>
                    <b-btn variant="info" class="mr-2" @click="data.cost.mandays = data.mandaystodo * data.contractsphases.find(x => x.id === data.cost.contractsphases_id).percentage">llogarit ditët e njeriut</b-btn>
                    <b-btn variant="danger" v-show="data.cost.id > 0" @click="deleteData">Fshije</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "contractcost",
    data() {
        return {
            show: false,
            data: null,
        }
    },
    methods: {
        loadData( contractsId, id ){
            this.show = false;
            this.openHttp("contracts_cost", { contracts_id: contractsId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("contracts_cost_save", this.data.cost ).then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("contracts_cost_delete", { contracts_id: this.data.cost.contracts_id, id: this.data.cost.id }).then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>
<template>
    <div>
        <b-card no-body>
            <b-tabs card>
                <b-tab title="Norme" active>
                    <b-btn class="mr-2" variant="primary" @click="loadStandards">Rifresko</b-btn>
                    <b-btn variant="success" @click="loadStandard(0)">E re</b-btn>
                    <standard ref="standard" @update="loadData"/>
                    <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="standards.items.data" :fields="standards.fields" :busy="standards.isBusy" :no-local-sorting="true" :sort-by.sync="standards.search.sort.by" :sort-desc.sync="standards.search.sort.desc" @sort-changed="sortingChangeStandards" @row-clicked="loadStandard($event.id)">
                        <template v-slot:table-busy>
                            <div class="text-center text-primary">
                                <b-spinner class="align-middle"/>
                            </div>
                        </template>
                        <template v-slot:empty>Nuk ka rezultat</template>
                    </b-table>
                    <tablebottom :busy="standards.isBusy" :currentpage="standards.search.page" :total="standards.items.total" @updateCurrentPage="standards.search.page = $event" @loadData="loadStandards"/>
                </b-tab>
                <b-tab title="IAF">
                    <iafcode ref="iafcode" @update="loadData"/>
                    <b-btn class="mr-2" variant="primary" @click="loadIafcodes">Rifresko</b-btn>
                    <b-btn variant="success" @click="$refs.iafcode.loadData(0)">E re</b-btn>
                    <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="iafcodes.items.data" :fields="iafcodes.fields" :busy="iafcodes.isBusy" :no-local-sorting="true" :sort-by.sync="iafcodes.search.sort.by" :sort-desc.sync="iafcodes.search.sort.desc" @sort-changed="sortingChangeIafcodes" @row-clicked="$refs.iafcode.loadData($event.id)">
                        <template v-slot:table-busy>
                            <div class="text-center text-primary">
                                <b-spinner class="align-middle"/>
                            </div>
                        </template>
                        <template v-slot:empty>Nuk ka rezultat</template>
                    </b-table>
                    <tablebottom :busy="iafcodes.isBusy" :currentpage="iafcodes.search.page" :total="iafcodes.items.total" @updateCurrentPage="iafcodes.search.page = $event" @loadData="loadIafcodes"/>
                </b-tab>
                <b-tab title="Risks level">
                    <standardiafcoderisklevel ref="standardiafcoderisklevel" @update="loadData"/>
                    <b-btn class="mr-2" variant="primary" @click="loadStandardsIafcodesRiskslevels">Rifresko</b-btn>
                    <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="standardsiafcodesriskslevels.items.data" :fields="standardsiafcodesriskslevels.fields" :busy="iafcodes.isBusy" :no-local-sorting="true" :sort-by.sync="standardsiafcodesriskslevels.search.sort.by" :sort-desc.sync="standardsiafcodesriskslevels.search.sort.desc" @sort-changed="sortingChangeStandardsIafcodesRiskslevels" @row-clicked="loadStandardsIafcodesRisklevel($event.standards_id, $event.iafcodes_id)">
                        <template v-slot:table-busy>
                            <div class="text-center text-primary">
                                <b-spinner class="align-middle"/>
                            </div>
                        </template>
                        <template v-slot:empty>Nuk ka rezultat</template>
                    </b-table>
                    <tablebottom :busy="standardsiafcodesriskslevels.isBusy" :currentpage="standardsiafcodesriskslevels.search.page" :total="standardsiafcodesriskslevels.items.total" @updateCurrentPage="standardsiafcodesriskslevels.search.page = $event" @loadData="loadStandardsIafcodesRiskslevels"/>
                </b-tab>
                <b-tab title="Mandays">
                    <manday ref="manday" @update="loadData"/>
                    <b-btn class="mr-2" variant="primary" @click="loadMandays">Rifresko</b-btn>
                    <b-btn variant="success" @click="loadManday(null, null, null, null)">E re</b-btn>
                    <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="mandays.items.data" :fields="mandays.fields" :busy="mandays.isBusy" :no-local-sorting="true" :sort-by.sync="mandays.search.sort.by" :sort-desc.sync="mandays.search.sort.desc" @sort-changed="sortingChangeMandays" @row-clicked="loadManday($event.standards_id, $event.riskslevels_id, $event.employees_min, $event.employees_max)">
                        <template v-slot:table-busy>
                            <div class="text-center text-primary">
                                <b-spinner class="align-middle"/>
                            </div>
                        </template>
                        <template v-slot:empty>Nuk ka rezultat</template>
                    </b-table>
                    <tablebottom :busy="mandays.isBusy" :currentpage="mandays.search.page" :total="mandays.items.total" @updateCurrentPage="mandays.search.page = $event" @loadData="loadMandays"/>
                </b-tab>
                <b-tab title="Steps">
                    <step ref="step" @update="loadData"/>
                    <b-btn class="mr-2" variant="primary" @click="loadSteps">Rifresko</b-btn>
                    <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="steps.items.data" :fields="steps.fields" :busy="steps.isBusy" :no-local-sorting="true" :sort-by.sync="steps.search.sort.by" :sort-desc.sync="steps.search.sort.desc" @sort-changed="sortingChangeSteps" @row-clicked="loadStep($event.standards_id, $event.auditsphases_id)">
                        <template v-slot:table-busy>
                            <div class="text-center text-primary">
                                <b-spinner class="align-middle"/>
                            </div>
                        </template>
                        <template v-slot:empty>Nuk ka rezultat</template>
                    </b-table>
                    <tablebottom :busy="steps.isBusy" :currentpage="steps.search.page" :total="steps.items.total" @updateCurrentPage="steps.search.page = $event" @loadData="loadSteps"/>
                </b-tab>
                <b-tab title="Accreditations">
                    <accreditation ref="accreditation" @update="loadData"/>
                    <b-btn class="mr-2" variant="primary" @click="loadAccreditations">Rifresko</b-btn>
                    <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="accreditations.items.data" :fields="accreditations.fields" :busy="accreditations.isBusy" :no-local-sorting="true" :sort-by.sync="accreditations.search.sort.by" :sort-desc.sync="accreditations.search.sort.desc" @sort-changed="sortingChangeAccreditations" @row-clicked="loadAccreditation($event.id)">
                        <template v-slot:table-busy>
                            <div class="text-center text-primary">
                                <b-spinner class="align-middle"/>
                            </div>
                        </template>
                        <template v-slot:empty>Nuk ka rezultat</template>
                    </b-table>
                    <tablebottom :busy="accreditations.isBusy" :currentpage="accreditations.search.page" :total="accreditations.items.total" @updateCurrentPage="accreditations.search.page = $event" @loadData="loadAccreditations"/>
                </b-tab>
                <b-tab title="Parameters">
                    <parametertype ref="parametertype" @update="loadData"/>
                    <b-btn class="mr-2" variant="primary" @click="loadParameterstypes">Rifresko</b-btn>
                    <b-btn variant="success" @click="loadParametertype(0)">E re</b-btn>
                    <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="parameterstypes.items.data" :fields="parameterstypes.fields" :busy="parameterstypes.isBusy" :no-local-sorting="true" :sort-by.sync="parameterstypes.search.sort.by" :sort-desc.sync="parameterstypes.search.sort.desc" @sort-changed="sortingChangeParameterstypes" @row-clicked="loadParametertype($event.id)">
                        <template v-slot:table-busy>
                            <div class="text-center text-primary">
                                <b-spinner class="align-middle"/>
                            </div>
                        </template>
                        <template v-slot:empty>Nuk ka rezultat</template>
                    </b-table>
                    <tablebottom :busy="parameterstypes.isBusy" :currentpage="parameterstypes.search.page" :total="parameterstypes.items.total" @updateCurrentPage="parameterstypes.search.page = $event" @loadData="loadParameterstypes"/>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import standard from './standardscomponents/standard.vue';
import iafcode from './standardscomponents/iafcode.vue';
import standardiafcoderisklevel from "./standardiafcoderisklevel.vue";
import manday from "./manday.vue";
import step from './step.vue';
import accreditation from './standardscomponents/accreditation.vue';
import parametertype from './standardscomponents/parametertype.vue';

export default {
    name: "standards",
    components : {
        standard, iafcode, standardiafcoderisklevel, manday, step, accreditation, parametertype
    },
    data() {
        return {
            standards: {
                isBusy: true,
                search: {
                    page: 1,
                    filters: { },
                    sort: {
                        by: 'standard',
                        desc: false,
                    },
                },
                fields: [{
                    key: 'standard',
                    label: 'Norma',
                    sortable: true,
                },{
                    key: 'active',
                    label: 'Stato',
                    sortable: true,
                    formatter: value => {
                        return value === true ? "Attivo" : "Non attivo"
                    },
                },{
                    key: 'standardspoints',
                    label: 'Punti',
                },{
                    key: 'iafcodes',
                    label: "IAF",
                },{
                    key: 'riskslevels',
                    label: "Levels",
                }],
                items: {
                    total: 0,
                    data: [],
                }
            },
            iafcodes: {
                isBusy: true,
                search: {
                    page: 1,
                    filters: {},
                    sort: {
                        by: 'code',
                        desc: false,
                    },
                },
                fields: [{
                    key: 'code',
                    label: 'Codice',
                    sortable: true
                },{
                    key: 'description',
                    label: 'Descrizione'
                }],
                items: {
                    total: 0,
                    data: [],
                }
            },
            standardsiafcodesriskslevels: {
                isBusy: true,
                search: {
                    page: 1,
                    filters: {},
                    sort: {
                        by: 'code',
                        desc: false,
                    },
                },
                fields: [{
                    key: 'standard',
                    label: 'Standard',
                    sortable: true
                },{
                    key: 'code',
                    label: 'Codice',
                    sortable: true
                },{
                    key: 'riskslevel',
                    label: "Risk",
                    sortable: true
                }],
                items: {
                    total: 0,
                    data: [],
                }
            },
            mandays: {
                isBusy: true,
                search: {
                    page: 1,
                    filters: {},
                    sort: {
                        by: 'standard',
                        desc: false,
                    },
                },
                fields: [{
                    key: 'standard',
                    label: 'Standard',
                    sortable: true
                },{
                    key: 'riskslevel',
                    label: 'Risk',
                    sortable: true
                },{
                    key: 'employees_min',
                    label: 'From',
                    sortable: true
                },{
                    key: 'employees_max',
                    label: 'To',
                    sortable: true
                },{
                    key: 'manday',
                    label: "Mandays",
                    sortable: true
                }],
                items: {
                    total: 0,
                    data: [],
                }
            },
            steps: {
                isBusy: true,
                search: {
                    page: 1,
                    filters: {},
                    sort: {
                        by: 'standard',
                        desc: false,
                    },
                },
                fields: [{
                    key: 'standard',
                    label: 'Standard',
                    sortable: true
                },{
                    key: "phase",
                    label: "Phase",
                    sortable: true
                },{
                    key: "points",
                    label: "Points"
                }],
                items: {
                    total: 0,
                    data: [],
                }
            },
            accreditations: {
                isBusy: true,
                search: {
                    page: 1,
                    filters: {},
                    sort: {
                        by: 'standard',
                        desc: false,
                    },
                },
                fields: [{
                    key: 'standard',
                    label: 'Standard',
                    sortable: true
                },{
                    key: "iafsok",
                    label: "IAF OK"
                },{
                    key: "iafsko",
                    label: "IAF KO"
                }],
                items: {
                    total: 0,
                    data: [],
                }
            },
            parameterstypes: {
                isBusy: true,
                search: {
                    page: 1,
                    filters: {},
                    sort: {
                        by: 'parametertype',
                        desc: false,
                    },
                },
                fields: [{
                    key: 'parametertype',
                    label: 'Parametri',
                    sortable: true
                },{
                    key: 'format',
                    label: "Formati",
                    sortable: true
                },{
                    key: 'id',
                    label: "Numri",
                    sortable: true
                }],
                items: {
                    total: 0,
                    data: [],
                }
            },
        }
    },
    computed: {
        winHeight (){
            return this.$store.getters.getWinHeight;
        },
    },
    created() {
        this.loadData();
    },
    methods: {
        loadData(){
            this.loadStandards();
            this.loadIafcodes();
            this.loadStandardsIafcodesRiskslevels();
            this.loadMandays();
            this.loadSteps();
            this.loadAccreditations();
            this.loadParameterstypes();
        },
        loadStandards(){
            this.standards.isBusy = true;
            this.openHttp("standards_standards", this.standards.search ).then((result) => {
                this.standards.isBusy = false;
                this.standards.items = result;
            })
        },
        sortingChangeStandards(ctx) {
            this.standards.search.sort.by = ctx.sortBy;
            this.standards.search.sort.desc = ctx.sortDesc;
            this.loadStandards();
        },
        loadStandard(id){
            this.$refs.standard.loadData(id);
        },
        loadIafcodes(){
            this.iafcodes.isBusy = true;
            this.openHttp("standards_iafcodes", this.iafcodes.search ).then((result) => {
                this.iafcodes.items = result;
                this.iafcodes.isBusy = false;
            })
        },
        sortingChangeIafcodes(ctx) {
            this.iafcodes.search.sort.by = ctx.sortBy;
            this.iafcodes.search.sort.desc = ctx.sortDesc;
            this.loadIafcodes();
        },
        loadStandardsIafcodesRiskslevels(){
            this.standardsiafcodesriskslevels.isBusy = true;
            this.openHttp("standards_standardsiafcodesriskslevels", this.standardsiafcodesriskslevels.search ).then((result) => {
                this.standardsiafcodesriskslevels.items = result;
                this.standardsiafcodesriskslevels.isBusy = false;
            })
        },
        sortingChangeStandardsIafcodesRiskslevels(ctx) {
            this.standardsiafcodesriskslevels.search.sort.by = ctx.sortBy;
            this.standardsiafcodesriskslevels.search.sort.desc = ctx.sortDesc;
            this.loadStandardsIafcodesRiskslevels();
        },
        loadStandardsIafcodesRisklevel( standardsId, iafcodesId ){
            this.$refs.standardiafcoderisklevel.loadData( standardsId, iafcodesId );
        },
        loadMandays(){
            this.mandays.isBusy = true;
            this.openHttp("standards_mandays", this.mandays.search ).then((result) => {
                this.mandays.items = result;
                this.mandays.isBusy = false;
            })
        },
        sortingChangeMandays(ctx) {
            this.mandays.search.sort.by = ctx.sortBy;
            this.mandays.search.sort.desc = ctx.sortDesc;
            this.loadMandays();
        },
        loadManday( standardsId, riskslevelsId, employeesMin, employeesMax ){
            this.$refs.manday.loadData( standardsId, riskslevelsId, employeesMin, employeesMax );
        },
        loadSteps(){
            this.steps.isBusy = true;
            this.openHttp("standards_steps", this.steps.search ).then((result) => {
                this.steps.items = result;
                this.steps.isBusy = false;
            })
        },
        sortingChangeSteps(ctx) {
            this.steps.search.sort.by = ctx.sortBy;
            this.steps.search.sort.desc = ctx.sortDesc;
            this.loadSteps();
        },
        loadStep( standardsId, auditsphasesId ){
            this.$refs.step.loadData( standardsId, auditsphasesId );
        },
        loadAccreditations(){
            this.accreditations.isBusy = true;
            this.openHttp("standards_accreditations", this.accreditations.search ).then((result) => {
                this.accreditations.items = result;
                this.accreditations.isBusy = false;
            })
        },
        sortingChangeAccreditations(ctx) {
            this.accreditations.search.sort.by = ctx.sortBy;
            this.accreditations.search.sort.desc = ctx.sortDesc;
            this.loadAccreditations();
        },
        loadAccreditation( id ){
            this.$refs.accreditation.loadData( id );
        },
        loadParameterstypes(){
            this.parameterstypes.isBusy = true;
            this.openHttp("standards_parameterstypes", this.parameterstypes.search ).then((result) => {
                this.parameterstypes.items = result;
                this.parameterstypes.isBusy = false;
            })
        },
        sortingChangeParameterstypes(ctx) {
            this.parameterstypes.search.sort.by = ctx.sortBy;
            this.parameterstypes.search.sort.desc = ctx.sortDesc;
            this.loadParameterstypes();
        },
        loadParametertype( id ){
            this.$refs.parametertype.loadData( id );
        }
    }
}
</script>
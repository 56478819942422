<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Log" @hidden="closeForm()" @close="closeForm()">
      <b-row>
        <b-col lg="4">
          <b-form-group label="Azienda">
            <b-form-input v-model="data.auditlog.company"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Ente">
            <b-form-input v-model="data.auditlog.certificationbody"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Data report">
            <b-form-input v-model="data.auditlog.datereport" type="date"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Sotto accreditamento">
            <b-form-select v-model="data.auditlog.underaccreditation" :options="underAccreditationCombo" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="2">
          <b-form-group label="Fase">
            <b-form-select v-model="data.auditlog.contractsphases_id" :options="data.contractsphases" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Lead auditor">
            <b-form-input v-model="data.auditlog.leadauditor"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Ruolo">
            <b-form-select v-model="data.auditlog.usersroles_id" :options="data.usersroles" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Giorni uomo">
            <b-form-input v-model="data.auditlog.manday" min="0" step="0.0001" type="number"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Scopo">
        <b-form-textarea v-model="data.auditlog.scope" rows="2"/>
      </b-form-group>
      <b-form-group label="Norme">
        <multiselect v-model="standardsSupport" :options="data.standards" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.auditlog.standards = updateCombo(standardsSupport)"/>
      </b-form-group>
      <b-form-group label="IAF">
        <multiselect v-model="iafcodesSupport" :options="data.iafcodes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.auditlog.iafcodes = updateCombo(iafcodesSupport)"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.auditlog.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "curriculumauditlog",
  data() {
    return {
      show: false,
      data: null,
      standardsSupport: [],
      iafcodesSupport: [],
      underAccreditationCombo: [{
        id: false,
        val: "Non presenziato"
      },{
        id: true,
        val: "Sotto accreditamento"
      }]
    }
  },
  methods: {
    loadData(usersId, id){
      this.openHttp("curricula_auditlog", { id: id, users_id: usersId }).then((result) => {
        this.standardsSupport = this.convertCombo(result.standards, result.auditlog.standards);
        this.iafcodesSupport = this.convertCombo(result.iafcodes, result.auditlog.iafcodes);
        this.data = result
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("curricula_auditlog_save", this.data.auditlog, "Log salvato!").then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("curricula_auditlog_delete", { id: this.data.auditlog.id }, "Log salvato!").then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.$emit('update');
      this.data = null;
      this.show = false;
    }
  }
}
</script>
<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Consulenza" @hidden="closeForm" @close="closeForm">
      <b-row>
        <b-col lg="2">
          <b-form-group label="Inizio">
            <b-form-input v-model="data.consultancy.fromdate" type="date"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Fine">
            <b-form-input v-model="data.consultancy.todate" type="date"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Giornate">
            <b-form-input v-model="data.consultancy.daysdone" type="number"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="8">
          <b-form-group label="Azienda">
            <b-form-input v-model="data.consultancy.company"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Società di consulenza (per cui si lavora)">
            <b-form-input v-model="data.consultancy.contractor"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Settore">
        <b-form-textarea v-model="data.consultancy.sector" rows="2" max-rows="10"/>
      </b-form-group>
      <b-form-group label="Ruolo e Responsabilità">
        <b-form-textarea v-model="data.consultancy.responsibilities" rows="2" max-rows="10"/>
      </b-form-group>
      <b-form-group label="Norme">
        <multiselect v-model="standardsSupport" :options="data.standards" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.consultancy.standards = updateCombo(standardsSupport)"/>
      </b-form-group>
      <b-form-group label="IAFs">
        <multiselect v-model="iafcodesSupport" :options="data.iafcodes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.consultancy.iafcodes = updateCombo(iafcodesSupport)"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.consultancy.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "curriculumconsultancy",
  data() {
    return {
      show: false,
      data: null,
      standardsSupport: [],
      iafcodesSupport: []
    }
  },
  methods: {
    loadData(usersId, id){
      this.openHttp("curricula_consultancy", { id: id, users_id: usersId }).then((result) => {
        this.standardsSupport = this.convertCombo(result.standards, result.consultancy.standards);
        this.iafcodesSupport = this.convertCombo(result.iafcodes, result.consultancy.iafcodes);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("curricula_consultancy_save", this.data.consultancy, "Consulenza salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("curricula_consultancy_delete", { id: this.data.consultancy.id }, "Consulenza eliminata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.$emit('update');
      this.data = null;
      this.show = false;
    }
  }
}
</script>
<template>
    <div>
        <b-modal v-if="data!== null" v-model="show" title="Rezervimi" size="lg">
            <b-row>
                <b-col lg="2">
                    <b-form-group label="Nga">
                        <b-form-input type="time" v-model="data.reservation.fromtime"/>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                    <b-form-group label="Te">
                        <b-form-input type="time" v-model="data.reservation.totime"/>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                    <b-form-group label="Tipologjia">
                        <b-form-select v-model="data.reservation.auditstypes_id" :options="data.auditstypes" value-field="id" text-field="val" @change="suggestionData()"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-form-group label="Faqe">
                <b-form-select v-model="data.reservation.auditssites_id" :options="data.auditssites.map(function(element) { return { id: element.id, val: element.val + (element.main ? ' Principale' : '') + (element.registered ? ' Legale' : '') + (element.yard ? ' Temporanea' : '')} })" value-field="id" text-field="val"  @change="suggestionData()"/>
            </b-form-group>
            <b-form-group label="Ekipi">
                <b-form-checkbox-group v-model="data.reservation.usersstandardsphasesrolesdates">
                    <div v-for="(item, index) of data.usersstandardsphasesrolesdates" :key="index">
                        <strong>{{ item.role + " " + item.user }}</strong>
                        <div v-for="(item2, index2) of item.phasesstandards" :key="index2">
                            {{ item2.phase }}
                            <b-form-checkbox v-for="(item3, index3) of item2.standards" :key="index3" :value="item3.id"  @change="suggestionData()">{{ item3.standard }}</b-form-checkbox>
                        </div>
                    </div>
                </b-form-checkbox-group>
            </b-form-group>
            <b-form-group label="Pikat" v-show="suggestions.length > 0">
                <b-form-checkbox-group v-model="data.reservation.standardspoints">
                    <div v-for="(item, index) of data.points" :key="index">
                        <strong>{{ item.standard }}</strong>
                        <br>
                        <b-form-checkbox v-for="(item2, index2) of item.points.filter( x => suggestions.includes(x.id))" :key="index2" :value="item2.id">{{ item2.val }}</b-form-checkbox>
                    </div>
                </b-form-checkbox-group>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Ruaj</b-btn>
                    <b-btn variant="danger" class="mr-2" v-show="data.reservation.id > 0" @click="deleteData">Fshije</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "reservation",
    data() {
        return {
            data: null,
            show: true,
            suggestions: [],
        }
    },
    methods: {
        loadData( auditsId, date, id ){
            this.openHttp("plans_reservation", { audits_id: auditsId, id: id, date: date }).then((result) => {
                this.data = result;
                this.show = true;
                this.suggestionData();
            })
        },
        suggestionData(){
            this.openHttp("plans_reservation_standardspoints", { id: this.data.reservation.id, audits_id: this.data.reservation.audits_id, types_id: this.data.reservation.auditstypes_id, sites_id: this.data.reservation.auditssites_id, usersstandardsphasesrolesdates: this.data.reservation.usersstandardsphasesrolesdates } ).then((result) => {
                this.suggestions = result;
                this.data.reservation.standardspoints = this.data.reservation.standardspoints.filter(value => this.suggestions.includes(value));
            })
        },
        saveData(){
            this.saveHttp("plans_reservation_save", this.data.reservation ).then((result) => {
                if (result.result === true) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("plans_reservation_delete", { id: this.data.reservation.id } ).then((result) => {
                if (result.result === true) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit("update");
        },
    },
}
</script>
<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Kërkesa për ofertë" scrollable @hidden="closeForm" @close="closeForm">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Kërkesa për ofertë" active>
            <b-row>
              <b-col lg="4">
                <company ref="company" @update="loadData(data.offer.id)"/>
                <b-form-group>
                  <label>Kompani</label> <b-link v-show="data.offer.id > 0" @click="$refs.company.loadData( data.offer.companies_id )"> ju hapni</b-link>
                  <b-form-select v-model="data.offer.companies_id" :options="data.companies" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Referuesi">
                  <b-form-select v-model="data.offer.workers_id" :options="data.workers.filter(x => x.companies_id === data.offer.companies_id)" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Tipo">
                  <b-form-select v-model="data.offer.contractstypes_id" :options="data.contractstypes" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Data e aplikimi">
                  <b-form-input type="date" v-model="data.offer.create_date"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Standarti">
              <offerstandard ref="standard" @update="loadData(data.offer.id)"/>
              <b-btn variant="primary" class="mr-2" size="sm" @click="$refs.standard.loadData( data.offer.id, 0)" :disabled="data.offer.id === 0">I ri</b-btn>
              <b-btn variant="info" size="sm" @click="calculateMandays" :disabled="data.offer.id === 0">llogarit dite/njerez</b-btn>
              <b-table no-border-collapse :sticky-header="(winHeight - 205) / 3 + 'px'" class="mt-3" :items="data.standards" :fields="fields.standards" @row-clicked="$refs.standard.loadData( data.offer.id, $event.id)" :tbody-tr-class="rowClassStandards"/>
            </b-form-group>
            <b-form-group label="Note">
              <b-form-textarea v-model="data.offer.note" rows="2"/>
            </b-form-group>
          </b-tab>
          <b-tab :disabled="data.offer.id === 0">
            <template #title>
              <h5 v-if="data.sites.length === 0"><b-badge variant="danger">Adresa Completare!</b-badge></h5><span v-else>Adresa</span>
            </template>
            <offersite ref="site" @update="loadData(data.offer.id)"/>
            <b-btn variant="primary" class="mr-2" size="sm" @click="$refs.site.loadData(data.offer.id, 0)">I ri</b-btn>
            <b-btn variant="info" size="sm" @click="allSites">Importa tutti i siti</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 335)+ 'px'" class="mt-3" :items="data.sites" :fields="fields.sites" @row-clicked="$refs.site.loadData(data.offer.id, $event.id)"/>
          </b-tab>
          <b-tab title="" :disabled="data.offer.id === 0">
            <template #title>
              <h5 v-if="data.scopes.length === 0"><b-badge variant="danger">Fusha Completare!</b-badge></h5><span v-else>Fusha</span>
            </template>
            <offerscope ref="scope" @update="loadData(data.offer.id)"/>
            <b-btn variant="primary" size="sm" class="mr-2" @click="$refs.scope.loadData(data.offer.id, 0)">I ri</b-btn>
            <b-btn variant="info" size="sm" @click="allScopes">Importa tutti gli scopi</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 335) + 'px'" class="mt-3" :items="data.scopes" :fields="fields.scopes" @row-clicked="$refs.scope.loadData(data.offer.id, $event.id)"/>
          </b-tab>
          <b-tab :disabled="data.offer.id === 0">
            <template #title>
              <h5 v-if="data.parameters.findIndex(x => x.val === null) > -1"><b-badge variant="danger">Parametra Completare!</b-badge></h5><span v-else>Parametra</span>
            </template>
            <offerparameter ref="parameter" @update="loadData(data.offer.id)"/>
            <b-table no-border-collapse :sticky-header="(winHeight - 335) + 'px'" :items="data.parameters" :fields="fields.parameters" @row-clicked="$refs.parameter.loadData( data.offer.id, $event.id)"/>
          </b-tab>
          <b-tab :disabled="data.offer.id === 0">
            <template #title>
              <h5 v-if="data.costs.length === 0"><b-badge variant="danger">Cmimi Completare!</b-badge></h5><span v-else>Cmimi</span>
            </template>
            <offercost ref="cost" @update="loadData(data.offer.id)"/>
            <b-btn variant="primary" size="sm" @click="$refs.cost.loadData(data.offer.id, 0)">I ri</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 335) + 'px'" class="mt-3" :items="data.costs" :fields="fields.costs" @row-clicked="$refs.cost.loadData(data.offer.id, $event.id)"/>
          </b-tab>
          <b-tab title="Dokumenta" :disabled="data.offer.id === 0">
            <offerdocument ref="document" @update="loadData(data.offer.id)"/>
            <b-btn variant="primary" class="mr-2" size="sm" @click="loadDocument(0)">I ri</b-btn>
            <b-btn variant="info" class="mr-2" size="sm" @click="prepareDocuments">Gjeneron</b-btn>
            <b-btn variant="primary" size="sm" @click="$refs.document.downloadDocumentAll()">Shkarkoni gjithçka</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 205)+ 'px'" class="mt-3" :items="data.documents" :fields="fields.documents" @row-clicked="loadDocument($event.id)">
              <template v-slot:cell(id)="row">
                <b-btn variant="primary" size="sm" @click="$refs.document.downloadDocument(row.item.id)">Shkarko</b-btn>
              </template>            
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <offerconfirmation ref="confirmation" @update="closeForm()"/>
          <b-btn variant="primary" v-show="data.offer.id > 0" class="mr-2" @click="loadData(data.offer.id)">Përditëso</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData">Ruaj</b-btn>
          <b-btn variant="danger" class="mr-2" v-show="data.offer.id > 0" @click="deleteData">Fshije</b-btn>
          <b-btn variant="warning" v-show="data.offer.id > 0" @click="$refs.confirmation.loadData(data.offer.id)">Konfirmim</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import offerstandard from './offerstandard.vue';
import offersite from './offersite.vue';
import offerscope from './offerscope.vue';
import offercost from './offercost.vue';
import offerparameter from './offerparameter.vue';
import offerconfirmation from './offerconfirmation.vue';
import offerdocument from './offerdocument.vue';

export default {
  name: "offer",
  components: { offerstandard, offersite, offerscope, offercost, offerparameter, offerconfirmation, offerdocument },
  data() {
    return {
      show: true,
      data: null,
      showMail: false,
      fields: {
        standards: [{
          key: 'standard',
          label: "Norma",
        },{
          key: 'iafcodes',
          label: 'IAF',
        },{
          key: 'riskslevel',
          label: 'Risku',
        },{
          key: 'accreditated',
          label: 'Akredituar',
          formatter: value => {
            return value === null ? "?" : (value === false ? "Non accreditato" : "Accreditato");
          },
        },{
          key: 'mandaystiletable',
          label: 'Tabela d/nj',
        },{
          key: 'mandaysreduced',
          label: "D/nj Zvogeluar",
        },{
          key: 'excludedpoints',
          label: 'Perjashtime',
        },{
          key: 'note',
          label: 'Shenime',
        }],
        sites: [{
          key: 'address',
          label: "Adresë",
        },{
          key: 'city',
          label: 'Qyteti',
          sortable: true,
        },{
          key: 'postalcode',
          label: 'Kodi Postar',
          sortable: true,
        },{
          key: 'region',
          label: "Rajon",
          sortable: true,
        },{
          key: "state",
          label: "Shtetit",
          sortable: true,
        },{
          key: 'active',
          label: 'Aktive',
          sortable: true,
          formatter: value => {
            return value === true ? 'Po' : 'Nr';
          },
        },{
          key: 'registered',
          label: 'Regjistruar',
          sortable: true,
          formatter: value => {
            return value === true ? 'Po' : 'Nr';
          },
        },{
          key: 'iafcodes',
          label: 'Kodet IAF'
        },{
          key: "totalworkers",
          label: "Totali i punëtorëve",
        },{
          key: "effectiveworkers",
          label: "Punëtorët efektivë",
        },{
          key: "subcontractorsworkers",
          label: "Punëtorët e nënkontraktorëve",
        },{
          key: "shiftsworkers",
          label: "Turne",
        },{
          key: "processesonsite",
          label: "Proceset në vend",
        },{
          key: "processessubcontractors",
          label: "Aktivitet i nenkontraktorve",
        }],
        scopes: [{
          key: 'lang',
          label: 'Gjuha',
        },{
          key: 'scope',
          label: 'Fushëveprimi',
        }],
        costs: [{
          key: 'year',
          label: 'Viti',
        },{
          key: 'phase',
          label: 'Faza',
        },{
          key: 'cost',
          label: 'Çmimi (€)',
        },{
          key: 'mandays',
          label: 'Ditët e mashkullit',
        }],
        parameters: [{
          key: 'type',
          label: 'Djalë',
        },{
          key: 'val',
          label: 'Vlera',
          formatter: (value, key, item) => {
            if ( item.format === 'date' ){
              if (value == null) return null;
              var d = new Date(value);
              return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
            }
            if ( item.format === 'bool' && value !== null ) return value === "true" ? 'Po' : 'Nr';
            return value;
          }
        }],
        audits: [{
          key: "plandate",
          label: 'Pianificato',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'expiracy',
          label: 'Scadenza',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'standardsphases',
          label: 'Norme',
          formatter: value => {
            if (value == null) return null;
            return value.join("\n");
          },
        }],
        certificates: [{
          key: 'standard',
          label: "Norma",
        },{
          key: 'certificatecode',
          label: 'Certifikata',
        },{
          key: 'certificatefirstissue',
          label: 'Numri 1',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'certificateissue',
          label: 'Çështje',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'certificatevalidfrom',
          label: 'Vlefshme nga',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'certificateexpirancy',
          label: 'Afati i fundit',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        }],
        documents: [{
          key: "filename",
          label: "Skedari",
          sortable: true,
        },{
          key: "datetime",
          label: "Përditëso",
          formatter: value => {
            let d = new Date(value);
            return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':') + " " + [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          sortable: true,
        },{
          key: "id",
          label: "",
        }],
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    rowClassStandards(item, type){
      if (!item || type !== 'row') return '';
      return item.active === false ? 'table-danger' : "";
    },
    loadData( id ){
      if ( this.data === null ) this.show = false;
      this.openHttp("offers_offer", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    calculateMandays(){
      this.saveHttp("offers_calculate", { id: this.data.offer.id }).then((result) => {
        if ( result.result == true ) this.loadData( this.data.offer.id );
      })
    },
    allSites(){
      this.saveHttp("offers_allsites", { id: this.data.offer.id }).then((result) => {
        if ( result.result == true ) this.loadData( this.data.offer.id );
      })
    },
    allScopes(){
      this.saveHttp("offers_allscopes", { id: this.data.offer.id }).then((result) => {
        if ( result.result == true ) this.loadData( this.data.offer.id );
      })
    },
    loadDocument(id){
      this.$refs.document.loadData( this.data.offer.id, id );
    },
    prepareDocuments(){
      this.saveHttp("offers_documentprepare", { id: this.data.offer.id }).then((result) => {
        if ( result.result == true ) this.loadData( this.data.offer.id );
      })
    },
    saveData(){
      this.saveHttp("offers_offer_save", this.data.offer).then((result) => {
        if ( result.result == true ) this.loadData(result.id);
      })
    },
    deleteData(){
      this.deleteHttp("offers_offer_delete", { id: this.data.offer.id }).then((result) => {
          if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>
<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" title="Parametri" @hidden="closeForm" @close="closeForm">
            <b-form-group :label="data.parameter.parametertype">
                <b-form-input v-if="data.parameter.format === 'string'" v-model="data.parameter.val"/>
                <b-form-textarea v-if="data.parameter.format === 'text'" v-model="data.parameter.val" rows="3"/>
                <b-form-input type="number" v-if="data.parameter.format === 'number'" v-model="data.parameter.val"/>
                <b-form-input type="date" v-if="data.parameter.format === 'date'" v-model="data.parameter.val"/>
                <b-form-select v-if="data.parameter.format === 'bool'" v-model="data.parameter.val" :options="yesnoCombo" value-field="id" text-field="val"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" @click="saveData">Ruaj</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "offerparameter",
    data() {
        return {
            show: false,
            data: null,
            yesnoCombo: [{
                id: false,
                val: "Nr",
            },{
                id: true,
                val: "Po",
            }]
        }
    },
    methods: {
        loadData( contractsId, id ){
            this.show = false;
            this.openHttp("offers_parameter", { contracts_id: contractsId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("offers_parameter_save", this.data.parameter).then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: localStorage.getItem("token"),
        lang: localStorage.getItem("lang"),
        routes: localStorage.getItem("routes") != null ? localStorage.getItem("routes") : [],
        windowWidth: 0,
        windowHeight: 0,
        lastMarketingphonesId: null,
        lastEmailAddressId: null,
        lastEmailTemplatesId: null,
    },
    getters: {
        isLogged : state => {
            return state.token === null ? false : true;
        },
        getToken : state => {
            return state.token;
        },
        getLang : state => {
            return state.lang;
        },
        getRoutes : state => {
            return Array.isArray(state.routes) ? state.routes : state.routes.split(",");
        },
        getWinHeight : state => {
            return state.windowHeight;
        },
        getWinWidth : state => {
            return state.windowWidth;
        },
    },
    mutations: {
        setToken : (state, token) => {
            if (token != null){
                localStorage.setItem("token", token);
                state.token = token;
            }
        },
        setLang : (state, lang) => {
            localStorage.setItem("lang", lang);
            state.lang = lang;
        },
        setRoutes : (state, routes) => {
            localStorage.setItem("routes", routes);
            state.routes = routes;
        },
        setWinHeight : (state, height) => {
            state.windowHeight = height;
        },
        setWinWidth : (state, width) => {
            state.windowWidth = width;
        },
        unSetToken : (state) => {
            localStorage.clear();
            state.token = null;
            state.routes = [];
        }
    },
})
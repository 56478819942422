<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Audit" scrollable @hidden="closeForm" @close="closeForm">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Auditimi" active>
            <b-row>
              <b-col lg="4">
                <b-form-group>
                  <contract ref="contract" @update="loadData(data.audit.id)"/>
                  <label>Kontratës</label> - <b-link @click="$refs.contract.loadData(data.audit.contracts_id)">Ti hapesh</b-link>
                  <b-form-select v-model="data.audit.contracts_id" :options="data.contracts" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Data e planit">
                  <b-form-input type="date" v-model="data.audit.plandate"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Afati i fundit">
                  <b-form-input type="date" v-model="data.audit.expiracy"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Gjuhët">
                  <multiselect v-model="data.audit.languagesSupport" :options="data.languages" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.audit.languages = updateCombo(data.audit.languagesSupport)"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Normat, fazat dhe pikat">
              <planstandardphase ref="standardphase" @update="loadData(data.audit.id)"/>
              <b-btn variant="primary" size="sm" @click="$refs.standardphase.loadData( data.audit.id, 0)">I ri</b-btn>
              <b-table responsive :items="data.standardsphases" :fields="fields.standardsphases" class="mt-3" @row-clicked="$refs.standardphase.loadData( data.audit.id, $event.id)"/>
            </b-form-group>
            <b-form-group label="Shënim">
              <b-form-textarea v-model="data.audit.note" rows="2"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Faqet">
            <plansite ref="site" @update="loadData(data.audit.id)"/>
            <b-btn size="sm" variant="primary" @click="$refs.site.loadData(0, data.audit.id)">I ri</b-btn>
            <b-table responsive :fields="fields.sites" :items="data.sites" class="mt-3" @row-clicked="$refs.site.loadData($event.id, data.audit.id)"/>
          </b-tab>
          <b-tab title="Planifikimi Kalendari">
            <b-table responsive :fields="fields.control" :items="data.control">
              <template v-slot:cell(team)="row">
                <div v-for="item in row.value" :key="item.date">
                  <font :color="item.iafsko !== null ? 'red' : 'green'">{{ item.date }}:
                    {{ item.team }}
                    {{ item.iafsok }}
                    <span v-if="item.iafsko !== null">Ato mungojnë {{ item.iafsko }}</span>
                  </font>
                </div>
              </template>
            </b-table>
            <plancalendardate ref="calendardate" @update="loadData(data.audit.id)"/>
            <b-btn class="mr-2 mb-2" style="float: left" size="sm">{{ calendar.month + '-' + calendar.year}}</b-btn>
            <b-btn variant="primary" class="mr-2 mb-2" style="float: left" size="sm" @click="calendarPreviousMonth">Përpara</b-btn>
            <b-btn variant="primary" class="mr-2 mb-2" style="float: left" size="sm" @click="calendarNextMonth">Pas</b-btn>
            <b-form-input size="sm" v-model="calendar.filterUser" style="width: 300px" v-if="calendar.calendar.length > 0" />
            <b-table-simple hover responsive bordered :sticky-header="(winHeight - 500) + 'px'" v-if="calendar.calendar.length > 0">
              <b-thead head-variant="light">
                <b-th>Personi</b-th>
                <b-th>Kualifikimet</b-th>
                <b-th>E pranueshme</b-th>
                <b-th v-for="(item, index) in calendar.days" :key="index">{{ item }}</b-th>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(item, index) in calendar.calendar.filter( x => x.user.toLowerCase().indexOf( calendar.filterUser.toLowerCase() ) > -1 ) " :key="index">
                  <b-td>
                    <font :color="item.active === false ? 'red' : ''">{{ item.user }}</font>
                  </b-td>
                  <b-td>
                    <div v-for="(item2, index2) of item.qualification" :key="index2">
                      {{ item2.standard }} <strong>{{ item2.roles }}</strong> <font color="green">{{ item2.ok }}</font> <font color="red">{{ item2.ko }}</font>
                    </div>
                  </b-td>
                  <b-td>{{ item.completelevel }}%</b-td>
                  <b-td v-for="(item2, index2) in item.dates" :key="index2" :variant="item2.reserved === true ? 'success' : ( item2.companies !== null ? 'warning' : '' )" @click="loadCalendarDate( item.users_id, item2.day )">
                    <b-btn class="sm" variant="light" v-if="item2.companies !== null" :id ="'tooltip-target-' + item.users_id + '-' + item2.day">?</b-btn>
                    <b-tooltip v-if="item2.companies !== null" :target="'tooltip-target-' + item.users_id + '-' + item2.day" trigger="hover">{{ item2.companies }}</b-tooltip>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-tab>
          <b-tab title="Planifikimi Planifikimi">
            <planreservation ref="reservation" @update="loadData(data.audit.id)"/>
            <b-table-simple hover responsive>
              <b-thead>
                <b-tr>
                  <b-th>Nga</b-th>
                  <b-th>Te</b-th>
                  <b-th>Tipologjia</b-th>
                  <b-th>Ekipi</b-th>
                  <b-th>Pikat</b-th>
                  <b-th>Faqe</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-for="(item, index) of data.reservations">
                  <b-tr :key="index + '_tab'">
                    <b-th colspan="6">
                      <b-btn class="mr-2" size="sm">{{ item.dateclean }}</b-btn>
                      <b-btn class="mr-2" size="sm" :variant="item.todo === item.done ? 'success' : 'warning'">{{ (item.todo - item.done) !== 0 ? ('Ato mungojnë ' + (item.todo - item.done) + 'h') : 'OK' }}</b-btn>
                      <b-btn variant="primary" size="sm" @click="$refs.reservation.loadData( data.audit.id, item.date, 0 )">I ri</b-btn>
                    </b-th>
                  </b-tr>
                  <b-tr v-for="(item2, index2) of item.reservations" :key="index + '_tab_' + index2" @click="$refs.reservation.loadData( data.audit.id, item.date, item2.id)">
                    <b-td>{{ item2.fromtime }}</b-td>
                    <b-td>{{ item2.totime }}</b-td>
                    <b-td>{{ item2.auditstype }}</b-td>
                    <b-td>
                      <div v-for="(item3, index3) of item2.usersroles" :key="index3">
                        <nobr><strong>{{ item3.user + ' ' + item3.role }}</strong></nobr> {{ item3.standardsphases }}
                      </div>
                    </b-td>
                    <b-td>
                      <div v-for="(item3, index3) of item2.standardspoints" :key="index3">
                        <nobr><strong>{{ item3.standard }}</strong> {{ item3.points }}</nobr>
                      </div>
                    </b-td>
                    <b-td>{{ item2.site }}</b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </b-tab>
          <b-tab title="Komiteti Teknik">
            <planveto ref="veto" @update="loadData(data.audit.id)"/>
            <b-btn variant="primary" size="sm" @click="$refs.veto.loadData( data.audit.id, 0)">I ri</b-btn>
            <b-table hover responsive :items="data.vetoauditors" :fields="fields.vetos" :sticky-header="(winHeight - 420) + 'px'" class="mt-3" @row-clicked="$refs.veto.loadData(data.audit.id, $event.id)"/>
          </b-tab>
          <b-tab title="Dokumentet">
            <plandocument ref="document" @update="loadData(data.audit.id)"/>
            <b-btn variant="primary" class="mr-2" size="sm" @click="loadDocument(0)">I ri</b-btn>
            <b-btn variant="info" class="mr-2" size="sm" @click="prepareDocuments">Gjeneron</b-btn>
            <b-btn variant="primary" size="sm" @click="downloadDocumentAll">Shkarkoni gjithçka</b-btn>
            <b-table class="mt-2" :sticky-header="(winHeight - 205) + 'px'" :tbody-tr-class="rowClassDocuments" hover responsive :items="data.documents" :fields="fields.documents" @row-clicked="loadDocument($event.id)">
              <template v-slot:cell(id)="row">
                <b-btn variant="primary" v-show="row.item.check === true" size="sm" @click="$refs.document.downloadDocument(row.item.id)">Shkarko</b-btn>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="loadData(data.audit.id)">Përditëso</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData">Ruaj</b-btn>
          <b-btn variant="danger" class="mr-2" @click="deleteData">Fshije</b-btn>
          <b-btn variant="warning" @click="nextStepData">Auditimi u konfirmua</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import planstandardphase from './planstandardphase.vue';
import plansite from './plansite.vue';
import plancalendardate from './plancalendardate.vue';
import planreservation from './planreservation.vue';
import planveto from './planveto.vue';
import plandocument from './plandocument.vue';

export default {
  name: "plan",
  components: {
    planstandardphase, plansite, plancalendardate, planreservation, planveto, plandocument
  },
  data() {
    return {
      show: true,
      data: null,
      calendar: {
        filterUser: "",
        month: null,
        year: null,
        days: [],
        calendar: [],
        dates: [],
        fieldsControl: [{
            key: "standard",
            label: "Standard",
        },{
            key: "phase",
            label: "Faza"
        },{
            key: "mandaytodo",
            label: "Ditë për të bërë",
        },{
            key: "mandaydone",
            label: "Ditët e kryera",
        },{
            key: "missing",
            label: "I zhdukur",
            formatter: (value, key, item) => {
                return item.mandaytodo - item.mandaydone;
            },
        },{
            key: "team",
            label: "Ekipi",
        }]
      },
      fields: {
        sites: [{
          key: 'yard',
          label: "Tipologji",
          formatter: value => {
            return value === true ? "Oborr" : "Operative";
          }
        },{
          key: 'address',
          label: "Adresë",
        },{
          key: 'city',
          label: "Qyteti (kodi postar)",
        }],
        standardsphases: [{
          key: 'standard',
          label: "Standard",
        },{
          key: 'phase',
          label: 'Faza',
        },{
          key: 'standardspoints',
          label: 'Pikat',
        }],
        control: [{
          key: "standard",
          label: "Standard",
        },{
          key: "phase",
          label: "Faza"
        },{
          key: "mandaytodo",
          label: "njeriu ditë për të bërë",
        },{
          key: "mandaydone",
          label: "Ditë të krijuara nga njeriu",
        },{
          key: "missing",
          label: "i zhdukur",
          formatter: (value, key, item) => {
            return Math.round((item.mandaytodo - item.mandaydone) * 10000) / 10000;
          },
        },{
          key: "team",
          label: "Team",
        }],
        reservations: [{
          key: "fromtime",
          label: "Nga",
          formatter: value => {
            let d = value.split(":");
            return [ d[0], d[1] ].join(':');
          },
        },{
          key: "totime",
          label: "Te",
          formatter: value => {
            let d = value.split(":");
            return [ d[0], d[1] ].join(':');
          },
        },{
          key: "auditstype",
          label: "Tipologjia"
        },{
          key: "usersroles",
          label: "Ekipi",
        },{
          key: "standardspoints",
          label: "Pikat",
        },{
          key: "site",
          label: "Faqe",
        }],
        vetos: [{
          key: "user",
          label: "Veton"
        },{
          key: "role",
          label: "Roli",
        },{
          key: "standard",
          label: "Standard",
        }],
        documents: [{
          key: "filename",
          label: "Skedari",
          sortable: true,
        },{
          key: "datetime",
          label: "Përditëso",
          formatter: value => {
            let d = new Date(value);
            return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':') + " " + [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          sortable: true,
        },{
          key: "id",
          label: "",
        }],
      },
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    rowClassDocuments(item) {
      return item !== null && item.check === false ? 'table-danger' : '';
    },
    cleanDate(date){
      if (date == null) return null;
      var d = new Date(date);
      return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map( n => n < 10 ? `0${n}` : `${n}` ).join('-');
    },
    loadData(id){
      if (this.data === null) this.calendar.filterUser = "";
      let month = null;
      let year = null;
      if ( this.data === null ){
        this.show = false;
      } else {
        month = this.data.calendarmonth;
        year = this.data.calendaryear;
      }
      if (this.data === null) this.show = false;
      this.openHttp("plans_audit", { id: id, month: month, year: year }).then((result) => {
        result.audit.languagesSupport = this.convertCombo(result.languages, result.audit.languages);
        this.data = result;
        this.show = true;
        this.loadCalendar();
      })
    },
    calendarPreviousMonth(){
        let date = new Date( this.calendar.year, this.calendar.month - 1, 1 );
        this.calendar.year = date.getMonth() === 0 ? date.getFullYear() - 1 : date.getFullYear();
        this.calendar.month = date.getMonth() === 0 ? 12 : date.getMonth();
        this.loadCalendar();
    },
    calendarNextMonth(){
        let date = new Date( this.calendar.year, this.calendar.month, 1 );
        this.calendar.year = date.getFullYear();
        this.calendar.month = date.getMonth() + 1;
        this.loadCalendar();
    },
    loadCalendar(){
      this.openHttp("plans_audit_calendar", { audits_id: this.data.audit.id, month: this.calendar.month !== null ? this.calendar.month : null, year: this.calendar.year !== null ? this.calendar.year : null }).then((result) => {
        this.calendar.month = result.month;
        this.calendar.year = result.year;
        this.calendar.days = result.days;
        this.calendar.calendar = result.calendar;
      })
    },
    loadCalendarDate(usersId, date){
      this.$refs.calendardate.loadData( this.data.audit.id, usersId, date );
    },
    loadDocument( id ){
      this.$refs.document.loadData( this.data.audit.id, id );
    },
    downloadDocument( id ){
      this.$refs.downloadFormId.value = id;
      this.$refs.downloadForm.submit();
    },
    downloadDocumentAll(){
      this.$refs.downloadFormIds.value = JSON.stringify(this.data.documents);
      this.$refs.downloadAllForm.submit();
    },
    prepareDocuments(){
      this.saveHttp("plans_documents_generate", { id: this.data.audit.id }).then((result) => {
        if ( result.result === true ) this.loadData( this.data.audit.id );
      })
    },
    saveData(){
      this.saveHttp("plans_audit_save", this.data.audit).then((result) => {
        if ( result.result == true ) this.loadData(result.id);
      })
    },
    deleteData(){
      this.deleteHttp("plans_audit_delete", { id: this.data.audit.id } ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    nextStepData(){
      this.deleteHttp("plans_next_step", { id: this.data.audit.id } ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.calendar.month = null;
      this.calendar.year = null;
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>

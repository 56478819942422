<template>
  <div>
    <b-modal v-model="show" v-if="data !== null" title="Worker" size="lg">
      <b-row>
        <b-col lg="2">
          <b-form-group label="Title">
            <b-form-input v-model="data.worker.title"/>
          </b-form-group>
        </b-col>
        <b-col lg="5">
          <b-form-group label="Firstname">
            <b-form-input v-model="data.worker.firstname"/>
          </b-form-group>
        </b-col>
        <b-col lg="5">
          <b-form-group label="Lastname">
            <b-form-input v-model="data.worker.lastname"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="2">
          <b-form-group label="Status">
            <b-form-select v-model="data.worker.active" :options="activeCombo" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Type">
            <b-form-select v-model="data.worker.internal" :options="internalCombo" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="8">
          <b-form-group label="Job">
            <b-form-input v-model="data.worker.job" maxlength="199"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Areas">
        <multiselect v-model="data.worker.workersrolesSupport" :options="data.workersroles" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.worker.workersroles = updateCombo(data.worker.workersrolesSupport)"/>
      </b-form-group>
      <b-form-group label="Note">
        <b-form-textarea v-model="data.worker.note" rows="2"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
          <b-btn variant="danger" v-show="data.worker.id > 0" @click="deleteData">Delete</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "companyworker",
  data() {
    return {
      show: false,
      data: null,
      activeCombo: [{
        id: true,
        val: 'Active',
      },{
        id: false,
        val: 'Not active',
      }],
      internalCombo: [{
        id: true,
        val: 'Internal',
      },{
        id: false,
        val: 'External',
      }],
    }
  },
  methods: {
    loadData( id, companies_id ){
      this.show = false;
      this.openHttp("companies_worker", { id: id, companies_id: companies_id }).then((result) => {
        result.worker.workersrolesSupport = this.convertCombo(result.workersroles, result.worker.workersroles);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("companies_worker_save", this.data.worker, "Worker saved!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
        return(true)
      })
    },
    deleteData(){
      this.deleteHttp("companies_worker_delete", { id: this.data.worker.id }, "Worker deleted!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    }
  },
}
</script>
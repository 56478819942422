<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="User" scrollable @hidden="closeForm" @close="closeForm">
      <b-form-group label="Fullname">
        <b-form-input v-model="data.user.fullname"/>
      </b-form-group>
      <b-form-group label="Username">
        <b-form-input v-model="data.user.username"/>
      </b-form-group>      
      <b-form-group label="Password">
        <b-form-input v-model="data.user.psw"/>
      </b-form-group>
      <b-form-group label="Status">
        <b-form-select v-model="data.user.active" :options="activeCombo" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Language">
        <b-form-select v-model="data.user.languages_id" :options="data.languages" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Suppliers">
        <multiselect v-model="suppliersSupport" :options="data.suppliers" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.user.suppliers = updateCombo(suppliersSupport)"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
          <b-btn variant="danger" v-show="data.user.id > 0" @click="deleteData">Delete</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "usersuser",
  data() {
    return {
      data: null,
      show: false,
      suppliersSupport: [],
      activeCombo: [{
        id: true,
        val: "Active",
      },{
        id: false,
        val: "Not Active",
      }]
    }
  },
  methods: {
    loadData( id ){      
      if ( this.data === null ) this.show = false;
      this.openHttp("users_user", { id: id }).then((result) => {
        this.suppliersSupport = this.convertCombo(result.suppliers, result.user.suppliers);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("users_user_save", this.data.user, "User saved!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("users_user_delete", { id: this.data.user.id }, "User deleted!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  }
}
</script>
<template>
    <div>
        <b-modal v-if="show" v-model="show" title="Norma dhe faza" @hidden="closeForm" @close="closeForm">
            <b-form-group label="Norma">
                <b-form-select v-model="data.standardphase.standards_id" :options="data.standards" value-field="id" text-field="val" @change="data.standardphase.points = []"/>
            </b-form-group>
            <b-form-group label="Faza">
                <b-form-select v-model="data.standardphase.auditsphases_id" :options="data.phases" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Pikat">
                <b-form-checkbox-group v-model="data.standardphase.points">
                    <b-form-checkbox v-for="(item, index) in data.points.filter(x => x.standards_id === data.standardphase.standards_id)" :key="index" :value="item.id">{{ item.val }}</b-form-checkbox>
                </b-form-checkbox-group>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Ruaj</b-btn>
                    <b-btn variant="danger" v-show="data.standardphase.id > 0" @click="deleteData">Fshije</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "planstandardphase",
    data() {
        return {
            show: false,
            data: null,
        }
    },
    methods: {
        loadData( auditsId, id ){
            this.openHttp("plans_standardphase", { audits_id: auditsId, id: id } ).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("plans_standardphase_save", this.data.standardphase ).then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("plans_standardphase_delete", { id: this.data.standardphase.id, audits_id: this.data.standardphase.audits_id } ).then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>
<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Komiteti teknik" scrollable @hidden="closeForm" @close="closeForm">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Auditimi" active>
            <b-row>
              <b-col lg="4">
                <b-form-group>
                  <contract ref="contract" @update="loadData(data.audit.id)"/>
                  <label>Kontratës</label> - <b-link @click="$refs.contract.loadData(data.audit.contracts_id)">Ti hapesh</b-link>
                  <b-form-input v-model="data.audit.contract"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Data e planit">
                  <b-form-input type="date" v-model="data.audit.plandate"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Afati i fundit">
                  <b-form-input type="date" v-model="data.audit.expiracy"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Gjuhët">
                  <b-form-input v-model="data.audit.languages"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Normat, fazat dhe pikat">
              <b-table responsive :items="data.standardsphases" :fields="fields.standardsphases"/>
            </b-form-group>
            <b-form-group label="Shënim">
              <b-form-textarea v-model="data.audit.note" rows="2"/>
            </b-form-group>
            <b-form-group label="Faqet">
              <b-table responsive :fields="fields.sites" :items="data.sites"/>
            </b-form-group>
            <b-form-group label="Rregjistrimi i informacionit, analiza e te dhenave dhe pregatitja e ofertes">
              <b-form-select v-model="data.audit.veto_offer_ok" :options="vetoCombo" size="sm" value-field="id" text-field="val"/>
              <b-form-textarea v-model="data.audit.veto_offer_note" rows="2" :disabled="data.audit.veto_offer_ok === null" class="mt-2"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Planifikimi Kalendari">
            <b-form-group label="Ekipi i auditimit">
              <b-table responsive :fields="fields.control" :items="data.control" :sticky-header="(winHeight - 355) + 'px'">
                <template v-slot:cell(team)="row">
                  <div v-for="item in row.value" :key="item.date">
                    <font :color="item.iafsko !== null ? 'red' : 'green'">{{ item.date }}:
                      {{ item.team }}
                      {{ item.iafsok }}
                      <span v-if="item.iafsko !== null">Ato mungojnë {{ item.iafsko }}</span>
                    </font>
                  </div>
                </template>
              </b-table>
            </b-form-group>
            <b-form-group label="Anëtarët e komitetit teknik">
              <b-table hover responsive :items="data.vetoauditors" :fields="fields.vetos" :sticky-header="(winHeight - 420) + 'px'" @row-clicked="$refs.veto.loadData( data.audit.id, $event.id)"/>
            </b-form-group>
            <b-form-group label="Emerimi i grupit te auditit, kompetenca dhe respektimi i afateve">
              <b-form-select v-model="data.audit.veto_team_ok" :options="vetoCombo" size="sm" value-field="id" text-field="val"/>
              <b-form-textarea v-model="data.audit.veto_team_note" rows="2" :disabled="data.audit.veto_team_ok === null" class="mt-2"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Planifikimi">
            <b-table hover responsive :items="data.reservations" :fields="fields.reservations" :sticky-header="(winHeight - 355) + 'px'"/>
            <b-form-group label="Hartimi i planeve te auditit">
              <b-form-select v-model="data.audit.veto_plan_ok" :options="vetoCombo" size="sm" value-field="id" text-field="val"/>
              <b-form-textarea v-model="data.audit.veto_plan_note" rows="2" :disabled="data.audit.veto_plan_ok === null" class="mt-2"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Raportoni">
            <b-table responsive :fields="fields.reports" :items="data.report" :sticky-header="(winHeight - 490) + 'px'">
              <template v-slot:cell(languages)="row">
                <b-row>
                  <b-col v-for="(item, index) of row.value" :key="index">
                    <strong>{{ item.lang }}: </strong>
                    {{ item.report }}
                  </b-col>
                </b-row>
              </template>
            </b-table>
            <b-form-group label="Realizimi i auditit, respektimi i afateve, kontrolli i aspekteve ligjore dhe kerkesave te standadit per te cilat kerkohet auditimi">
              <b-form-select v-model="data.audit.veto_report_ok" :options="vetoCombo" size="sm" value-field="id" text-field="val"/>
              <b-form-textarea v-model="data.audit.veto_report_note" rows="2" :disabled="data.audit.veto_report_ok === null" class="mt-2"/>
            </b-form-group>
          </b-tab>
          <!--
          <b-tab title="Pikat e forta">
            <b-table responsive :fields="fields.strengths" :items="data.strengths.strengths" :sticky-header="(winHeight - 355) + 'px'">
              <template v-slot:cell(strength)="row">
                <div v-for="(item, index) in row.value" :key="index">
                  <strong>{{ item.lang }}</strong> {{ item.strength }}
                </div>
              </template>
              <template v-slot:cell(points)="row">
                <div v-for="(item, index) in row.value" :key="index">
                  <strong>{{ item.standard }}</strong> {{ item.points }}
                </div>
              </template>
            </b-table>
          </b-tab>
          -->
          <b-tab title="Mundësitë dhe mospërputhjet">
            <b-form-group label="Shansi">
              <b-table responsive :fields="fields.opportunities" :items="data.opportunities">
                <template v-slot:cell(opportunity)="row">
                  <div v-for="(item, index) in row.value" :key="index">
                    <strong>{{ item.lang }}</strong> {{ item.opportunity }}
                  </div>
                </template>
                <template v-slot:cell(points)="row">
                  <div v-for="(item, index) in row.value" :key="index">
                    <strong>{{ item.standard }}</strong> {{ item.points }}
                  </div>
                </template>
              </b-table>
            </b-form-group>
            <b-form-group label="Mundësi për të mbyllur">
              <b-table responsive :fields="fields.opportunitiesClosure" :items="data.opportunitiestobeclosed">
                <template v-slot:cell(opportunity)="row">
                  <div v-for="(item, index) in row.value" :key="index">
                    <strong>{{ item.lang }}</strong> {{ item.opportunity }}
                  </div>
                </template>
                <template v-slot:cell(closure)="row">
                  <div v-for="(item, index) in row.value" :key="index">
                    <strong>{{ item.lang }}</strong> {{ item.closure }}
                  </div>
                </template>                        
                <template v-slot:cell(points)="row">
                  <div v-for="(item, index) in row.value" :key="index">
                    <strong>{{ item.standard }}</strong> {{ item.points }}
                  </div>
                </template>
              </b-table>
            </b-form-group>
            <b-form-group label="Mospërputhje">
              <b-table responsive :fields="fields.nonconformities" :items="data.nonconformities">
                <template v-slot:cell(nonconformity)="row">
                  <div v-for="(item, index) in row.value" :key="index">
                    <strong>{{ item.lang }}</strong> {{ item.nonconformity }}
                  </div>
                </template>
                <template v-slot:cell(causes)="row">
                  <div v-for="(item, index) in row.value" :key="index">
                    <strong>{{ item.lang }}</strong> {{ item.causes }}
                  </div>
                </template>
                <template v-slot:cell(treatment)="row">
                  <div v-for="(item, index) in row.value" :key="index">
                    <strong>{{ item.lang }}</strong> {{ item.treatment }}
                  </div>
                </template>
                <template v-slot:cell(correctiveaction)="row">
                  <div v-for="(item, index) in row.value" :key="index">
                    <strong>{{ item.lang }}</strong> {{ item.correctiveaction }}
                  </div>
                </template>
                <template v-slot:cell(effectiveness)="row">
                  <div v-for="(item, index) in row.value" :key="index">
                    <strong>{{ item.lang }}</strong> {{ item.effectiveness }}
                  </div>
                </template>
                <template v-slot:cell(points)="row">
                  <div v-for="(item, index) in row.value" :key="index">
                    <strong>{{ item.standard }}</strong> {{ item.points }}
                  </div>
                </template>
              </b-table>
            </b-form-group>
            <b-form-group label="interpretimi i jokonformiteteve dhe mbylljes se tyre">
              <b-form-select v-model="data.audit.veto_nonconformities_ok" :options="vetoCombo" size="sm" value-field="id" text-field="val"/>
              <b-form-textarea v-model="data.audit.veto_nonconformities_note" rows="2" :disabled="data.audit.veto_nonconformities_ok === null" class="mt-2"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Vendimi">
            <b-form-group label="Plotesimi i dokumentacionit te auditit, nxjerrja e evidencave dhe propozimi i grupit te auditit">
              <b-form-select v-model="data.audit.veto_audit_ok" :options="vetoCombo" size="sm" value-field="id" text-field="val"/>
              <b-form-textarea v-model="data.audit.veto_audit_note" rows="2" :disabled="data.audit.veto_audit_ok === null" class="mt-2"/>
            </b-form-group>
            <b-form-group label="Te tjera">
              <b-form-select v-model="data.audit.veto_other_ok" :options="vetoCombo" size="sm" value-field="id" text-field="val"/>
              <b-form-textarea v-model="data.audit.veto_other_note" rows="2" :disabled="data.audit.veto_other_ok === null" class="mt-2"/>
            </b-form-group>
            <b-form-group label="Vendimi i datës">
              <b-form-input type="date" v-model="data.audit.vetodate"/>
            </b-form-group>
            <b-form-group label="Certifikatat">
              <b-table responsive :fields="fields.certificates" :items="data.audit.certificates">
                <template v-slot:cell(certificatecode)="row">
                  <b-form-input v-model="row.item.certificatecode"/>
                </template>
                <template v-slot:cell(certificatefirstissue)="row">
                  <b-form-input type="date" v-model="row.item.certificatefirstissue"/>
                </template>
                <template v-slot:cell(certificateissue)="row">
                  <b-form-input type="date" v-model="row.item.certificateissue"/>
                </template>
                <template v-slot:cell(certificatevalidfrom)="row">
                  <b-form-input type="date" v-model="row.item.certificatevalidfrom"/>
                </template>
                <template v-slot:cell(certificateexpirancy)="row">
                  <b-form-input type="date" v-model="row.item.certificateexpirancy"/>
                </template>
              </b-table>
            </b-form-group>
          </b-tab>
          <b-tab :title="'Dokumentet ' + data.documents.length">
            <vetodocument ref="document" @update="loadData(data.audit.id)"/>
            <b-btn variant="info" class="mr-2" size="sm" @click="prepareDocuments">Gjeneron</b-btn>
            <b-table class="mt-2" :sticky-header="(winHeight - 205) + 'px'" :tbody-tr-class="rowClassDocuments" hover responsive :items="data.documents" :fields="fields.documents" @row-clicked="loadDocument($event.id)">
              <template v-slot:cell(id)="row">
                <b-btn variant="primary" v-show="row.item.check === true" size="sm" @click="$refs.document.downloadDocument(row.item.id)">Shkarko</b-btn>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="loadData(data.audit.id)">Përditëso</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData">Ruaj</b-btn>
          <b-btn variant="warning" @click="nextStepData">Vendimi i marrë</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import vetodocument from './vetodocument.vue';

export default {
  name: "veto",
  components: {
    vetodocument
  },
  data() {
    return {
      show: true,
      data: null,
      vetoCombo: [{
        id: null,
        val: "Në pritje"
      },{
        id: true,
        val: "Verifikuar"
      },{
        id: false,
        val: "I pa verifikuar"
      },],
      fields: {
        sites: [{
          key: 'yard',
          label: "Tipologji",
          formatter: value => {
            return value === true ? "Oborr" : "Operative";
          }
        },{
          key: 'address',
          label: "Adresë",
        },{
          key: 'city',
          label: "Qyteti (kodi postar)",
        }],
        standardsphases: [{
          key: 'standard',
          label: "Standard",
        },{
          key: 'phase',
          label: 'Faza',
        },{
          key: 'standardspoints',
          label: 'Pikat',
        }],
        control: [{
          key: "standard",
          label: "Standard",
        },{
          key: "phase",
          label: "Faza"
        },{
          key: "mandaytodo",
          label: "njeriu ditë për të bërë",
        },{
          key: "mandaydone",
          label: "Ditë të krijuara nga njeriu",
        },{
          key: "missing",
          label: "i zhdukur",
          formatter: (value, key, item) => {
            return Math.round((item.mandaytodo - item.mandaydone) * 10000) / 10000;
          },
        },{
          key: "team",
          label: "Team",
        }],
        reservations: [{
          key: "date",
          label: "Data",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "fromtime",
          label: "Nga",
          formatter: value => {
            let d = value.split(":");
            return [ d[0], d[1] ].join(':');
          },
        },{
          key: "totime",
          label: "Te",
          formatter: value => {
            let d = value.split(":");
            return [ d[0], d[1] ].join(':');
          },
        },{
          key: "auditstype",
          label: "Tipologjia"
        },{
          key: "usersroles",
          label: "Ekipi",
        },{
          key: "standardspoints",
          label: "Pikat",
        },{
          key: "site",
          label: "Faqe",
        }],
        vetos: [{
          key: "user",
          label: "Veton"
        },{
          key: "role",
          label: "Roli",
        },{
          key: "standard",
          label: "Standard",
        },{
          key: "veto_date",
          label: "Data",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: "veto_result",
          label: "Rezultati",
          formatter: value => {
            return value === null ? "Për tu vlerësuar" : ( value === true ? "Miratuar" : "Refuzoi" );
          },
        },{
          key: "veto_note",
          label: "Shënim",
        }],
        reports: [{
          key: "point",
          label: "Pika",
        },{
          key: "languages",
          label: "Raportoni",
        }],
        strengths: [{
          key: "strength",
          label: "Forcë",
        },{
          key: "points",
          label: "Pikat e normës",
        },{
          key: "usercheck",
          label: "",
        }],
        opportunities: [{
          key: "opportunity",
          label: "Shansi",
        },{
          key: "points",
          label: "Pikat e normës",
        }],
        opportunitiesClosure: [{
          key: "opportunity",
          label: "Shansi",
        },{
          key: "closure",
          label: "Mbyllja",
        },{
          key: "points",
          label: "Pikat e normës",
        }],
        nonconformities: [{
          key: "nonconformity",
          label: "Mospërputhje",
        },{
          key: "causes",
          label: "Shkaqet",
        },{
          key: "treatment",
          label: "Trajtimi",
        },{
          key: "correctiveaction",
          label: "Veprim korrigjues",
        },{
          key: "effectiveness",
          label: "Efektiviteti",
        },{
          key: "points",
          label: "Pikat e normës",
        }],
        certificates: [{
          key: "standard",
          label: "Standarde",
        },{
          key: "certificatecode",
          label: "Kodi i certifikatës",
        },{
          key: "certificatefirstissue",
          label: "Lëshimi i parë i certifikatës",
        },{
          key: "certificateissue",
          label: "Lëshimi i certifikatës",
        },{
          key: "certificatevalidfrom",
          label: "Certifikatë e vlefshme nga",
        },{
          key: "certificateexpirancy",
          label: "Skadimi i certifikatës",
        }],
        documents: [{
          key: "filename",
          label: "Skedari",
          sortable: true,
        },{
          key: "datetime",
          label: "Përditëso",
          formatter: value => {
            let d = new Date(value);
            return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':') + " " + [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          sortable: true,
        },{
          key: "id",
          label: "",
        }],
      },
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    rowClassDocuments(item) {
      return item !== null && item.check === false ? 'table-danger' : '';
    },
    loadData(id){
      if (this.data === null) this.show = false;
      this.openHttp("vetos_audit", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    loadDocument( id ){
      this.$refs.document.loadData( this.data.audit.id, id );
    },
    downloadDocument( id ){
      this.$refs.downloadFormId.value = id;
      this.$refs.downloadForm.submit();
    },
    downloadDocumentAll(){
      this.$refs.downloadFormIds.value = JSON.stringify(this.data.documents);
      this.$refs.downloadAllForm.submit();
    },
    prepareDocuments(){
      this.saveHttp("vetos_documents_generate", { id: this.data.audit.id }).then((result) => {
        if ( result.result === true ) this.loadData( this.data.audit.id );
      })
    },
    saveData(){
      this.saveHttp("vetos_audit_save", this.data.audit).then((result) => {
        if (result.result == true) this.loadData(this.data.audit.id);
      })
    },
    nextStepData(){
      this.deleteHttp("vetos_next_step", { id: this.data.audit.id } ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>

<template>
  <div>
    <b-modal v-model="show" size="xl" v-if="data !== null" title="Faqe">
      <b-row>
        <b-col lg="4">
          <b-form-group label="Adresë">
            <b-form-input v-model="data.site.address"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Qyteti">
            <b-form-input v-model="data.site.city"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Kodi Postar">
            <b-form-input v-model="data.site.postalcode"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Rajon">
            <b-form-input v-model="data.site.region"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Shtetit">
            <b-form-select v-model="data.site.states_id" :options="data.states" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="2">
          <b-form-group label="Aktive?">
            <b-form-select v-model="data.site.active" :options="yesnoActive" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Zyra e regjistruar?">
            <b-form-select v-model="data.site.registered" :options="yesnoActive" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Turne">
            <b-form-input v-model="data.site.shiftsworkers" type="number"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Totali i punëtorëve">
            <b-form-input v-model="data.site.totalworkers" type="number"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Punëtorët efektivë">
            <b-form-input v-model="data.site.effectiveworkers" type="number"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Punëtorët e nënkontraktorëve">
            <b-form-input v-model="data.site.subcontractorsworkers" type="number"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-group label="Proceset në vend">
            <b-form-textarea v-model="data.site.processesonsite"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-group label="Aktivitet i nenkontraktorve">
            <b-form-textarea v-model="data.site.processessubcontractors"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-group label="Kodet IAF">
            <multiselect v-model="data.site.iafcodesSupport" :options="data.iafcodes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.site.iafcodes = updateCombo(data.site.iafcodesSupport)"/>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Ruaj</b-btn>
          <b-btn variant="danger" v-show="data.site.id > 0" @click="deleteData">Fshije</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "companysite",
  data() {
    return {
      show: false,
      data: null,
      yesnoActive: [{
        id: true,
        val: "Po"
      },{
        id: false,
        val: "Nr"
      }]
    }
  },
  methods: {
    loadData( id, companies_id ){
      this.show = false;
      this.openHttp("companies_site", { id: id, companies_id: companies_id }).then((result) => {
        result.site.iafcodesSupport = this.convertCombo(result.iafcodes, result.site.iafcodes);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("companies_site_save", this.data.site).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("companies_site_delete", { id: this.data.site.id } ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    }
  },
}
</script>
<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" title="Faqe" @hidden="closeForm" @close="closeForm">
            <b-form-group label="Faqe">
                <b-form-select v-model="data.site.sites_id" :options="data.sites" value-field="id" text-field="val"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Ruaj</b-btn>
                    <b-btn variant="danger" v-show="data.site.sites_id > 0" @click="deleteData">Fshije</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "contractsite",
    data() {
        return {
            show: false,
            data: null,
        }
    },
    methods: {
        loadData( contractsId, id ){
            this.show = false;
            this.openHttp("contracts_site", { contracts_id: contractsId, id: id } ).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("contracts_site_save", this.data.site ).then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("contracts_site_delete", { sites_id: this.data.site.sites_id, contracts_id: this.data.site.contracts_id } ).then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>

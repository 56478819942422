<template>
    <div>
        <b-modal v-if="data!= null" v-model="show" title="Veton">
            <b-form-group label="Personi">
                <b-form-select v-model="data.veto.users_id" :options="data.users" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Roli">
                <b-form-select v-model="data.veto.usersroles_id" :options="data.usersroles" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Standard">
                <b-form-select v-model="data.veto.standards_id" :options="data.standards" value-field="id" text-field="val"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Ruaj</b-btn>
                    <b-btn variant="danger" v-show="data.veto.id > 0" @click="deleteData">Fshije</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "veto",
    data() {
        return {
            data: null,
            show: true,
        }
    },
    methods: {
        loadData( auditsId, id ){
            this.openHttp("plans_vetoauditor", { audits_id: auditsId, id: id } ).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("plans_vetoauditor_save", this.data.veto ).then((result) => {
                if ( result.result === true ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("plans_vetoauditor_delete", { id: this.data.veto.id, audits_id: this.data.veto.audits_id } ).then((result) => {
                if ( result.result === true ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>
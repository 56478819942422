<template>
  <div>
    <b-btn variant="primary" class="mr-1" size="sm" @click="previousMonth">&#60;</b-btn>
    <b-btn class="mr-1" size="sm" @click="loadCalendar">{{ (calendar.month + 1) + '-' + calendar.year }}</b-btn>
    <b-btn variant="primary" size="sm" @click="nextMonth">&#62;</b-btn>
    <b-table-simple :sticky-header="(winHeight - 170) + 'px'" class="mt-3">
      <b-thead>
        <b-tr>
          <b-th v-for="item in calendar.days" :key="item.id">{{ item.val }}</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="item in calendar.weeks" :key="item.week">
          <b-td v-for="item2 in item.days" :key="item2.day" :style="{ width: item2.id < 5 ? '18%' : '5%' }" style="border-right: 2px solid #dee2e6; border-top: 2px solid #dee2e6; border-left: 2px solid #dee2e6; border-bottom: 2px;">
            <div style="min-height: 150px">
              <div style="display: flex; justify-content: flex-end">
                <div>{{ item2.day.substring(8, 10) }}</div>
              </div>
              <div v-for="appointment in item2.appointments" :key="appointment.id" style="backgroundColor: #007bff; padding: 5px; margin-bottom: 5px; border-radius: 5px;">
                <b>{{ appointment.user }}<br></b>
                <div>{{ appointment.company + ' (' + appointment.standards + ')' }}</div>
              </div>
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
export default {
  name: "homepage",
  data() {
    return {
      calendar: {
        days: [{
          id: 0,
          val: "Monday",
        },{
          id: 1,
          val: "Tuesday",
        },{
          id: 2,
          val: "Wednesday",
        },{
          id: 3,
          val: "Thursday",
        },{
          id: 4,
          val: "Friday",
        },{
          id: 5,
          val: "Saturday",
        },{
          id: 6,
          val: "Sunday",
        }],
        daylist: [],
        weeks: [],
        month: null,
        year: null,
        show: false,
        data: [],
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  created(){
    this.loadCalendar();
  },
  methods: {
    previousMonth(){
      this.calendar.month = this.calendar.month - 1;
      if ( this.calendar.month === -1 ){
        this.calendar.month = 11;
        this.calendar.year = this.calendar.year - 1;
      }
      this.loadCalendar();
    },
    nextMonth(){
      this.calendar.month = this.calendar.month + 1;
      if ( this.calendar.month === 12 ){
        this.calendar.month = 0;
        this.calendar.year = this.calendar.year + 1;
      }
      this.loadCalendar();
    },
    loadCalendar(){
      this.calendar.show = false;
      let today = new Date();
      if ( this.calendar.month === null ) this.calendar.month = today.getMonth();
      if ( this.calendar.year === null ) this.calendar.year = today.getFullYear();
      let fromDate = new Date( this.calendar.year, this.calendar.month, 1);
      let day = fromDate.getDay();
      fromDate = new Date( fromDate.setDate(fromDate.getDate() - day + (day == 0 ? -6 : 1)));
      let toDate = new Date( this.calendar.year, this.calendar.month + 1, 0);
      toDate = new Date( toDate.setDate(toDate.getDate() - toDate.getDay() + 7 + 1) );
      this.calendar.weeks = [];
      this.openHttp("homepage_audits", { fromdate: fromDate, todate: toDate }).then((result) => {
        if ( result.result === true ){
          this.calendar.show = true;
          let dt = fromDate;
          dt.setDate( dt.getDate() + 1 );
          for(this.calendar.daylist = []; dt <= toDate; dt.setDate(dt.getDate()+1)) this.calendar.daylist.push( new Date( dt ) );
          this.calendar.daylist = this.calendar.daylist.map((v)=>v.toISOString().slice(0,10));
          for( let i = 0; i <= Math.floor(this.calendar.daylist.length / 7) - 1; i++ ){
            let week = {
              week: i,
              days: [],
            };
            for( let j = 0; j < 7; j++ ){
              week.days.push({
                id: j,
                day: this.calendar.daylist[ i * 7 + j ],
                appointments: result.data.filter(x => x.date === this.calendar.daylist[ i * 7 + j ]),
              })
            }
            this.calendar.weeks.push(week);
          }
        }
      })
    }
  }
}
</script>
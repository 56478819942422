<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" title="Parameter" @hidden="closeForm" @close="closeForm">
            <b-form-group label="Paramenter">
                <b-form-input v-model="data.parametertype.parametertype"/>
            </b-form-group>
            <b-form-group label="Format">
                <b-form-select v-model="data.parametertype.format" :options="comboFormat" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="SQL">
                <b-form-textarea v-model="data.parametertype.sqlvalid" rows="4"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
                    <b-btn variant="danger" v-show="data.parametertype.id> 0" @click="deleteData">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "parametertype",
    data() {
        return {
            show: false,
            data: null,
            comboFormat: [{
                id: "number",
                val: "Number"
            },{
                id: "bool",
                val: "Boolean"
            },{
                id: "text",
                val: "Text"
            },{
                id: "date",
                val: "Date"
            }]
        }
    },
    methods: {
        loadData(id){
            this.openHttp("standards_parametertype", { id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("standards_parametertype_save", this.data.parametertype, "Parameter salvato!" ).then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("standards_parametertype_delete", { id: this.data.parametertype.id }, "Parameter delete!" ).then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>
<template>
    <div>
        <b-modal v-if="data != null" v-model="show" size="xl" title="Standard" scrollable @hidden="closeForm" @close="closeForm">
            <b-card no-body>
                <b-tabs card>
                    <b-tab title="Norme" active>
                        <b-row>
                            <b-col lg="8">
                                <b-form-group label="Norma">
                                    <b-form-input v-model="data.standard.standard"/>
                                </b-form-group>
                            </b-col>
                            <b-col lg="4">
                                <b-form-group label="Stato">
                                    <b-form-select v-model="data.standard.active" :options="optionActive" value-field="id" text-field="val"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-form-group label="IAF">
                            <multiselect v-model="data.standard.iafcodesSupport" :options="data.iafcodes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.standard.iafcodes = updateCombo(data.standard.iafcodesSupport)"/>
                        </b-form-group>
                        <b-form-group label="Risks Levels">
                            <multiselect v-model="data.standard.riskslevelsSupport" :options="data.riskslevels" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.standard.riskslevels = updateCombo(data.standard.riskslevelsSupport)"/>
                        </b-form-group>
                        <b-form-group label="Punti">
                            <b-btn variant="primary" class="mb-2" @click="pointAdd">Nuovo</b-btn>
                            <b-table :sticky-header="(winHeight - 490) + 'px'" :items="data.standard.standardspoints" :fields="fields">
                                <template v-slot:cell(point)="row">
                                    <b-form-input v-model="row.item.point"/>
                                </template>
                                <template v-slot:cell(pointname)="row">
                                    <b-form-input v-model="row.item.pointname"/>
                                </template>
                                <template v-slot:cell(orderstandardspoint)="row">
                                    <b-form-input type="number" v-model="row.item.orderstandardspoint"/>
                                </template>
                                <template v-slot:cell(needyard)="row">
                                    <b-form-select v-model="row.item.needyard" :options="needYardCombo" value-field="id" text-field="val"/>
                                </template>
                                <template v-slot:cell(multisite)="row">
                                    <b-form-select v-model="row.item.multisite" :options="multisiteCombo" value-field="id" text-field="val"/>
                                </template>
                                <template v-slot:cell(id)="row">
                                    <b-btn variant="danger" size="sm" @click="data.standard.standardspoints.splice(row.index,1);">Elimina</b-btn>
                                </template>
                            </b-table>
                        </b-form-group>
                    </b-tab>
                    <b-tab title="Report">
                        <b-table :sticky-header="(winHeight - 390) + 'px'" :items="data.standard.standardspointslanguages" :fields="fieldsPointLanguage">
                            <template v-slot:cell(report)="row">
                                <b-form-textarea v-model="row.item.report" rows="10"/>
                            </template>
                        </b-table>
                    </b-tab>
                </b-tabs>
            </b-card>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Ruaj</b-btn>
                    <b-btn variant="danger" v-show="data.standard.id > 0" @click="deleteData">Fshije</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "standard",
    data() {
        return {
            show: false,
            data: null,
            optionActive: [{
                id: true,
                val: "Attivo",
            },{
                id: false,
                val: "Non attivo",
            }],
            needYardCombo: [{
                id: true,
                val: 'Cantiere',
            },{
                id: false,
                val: 'Solo sede',
            }],
            multisiteCombo: [{
                id: true,
                val: 'Tutti i siti',
            },{
                id: false,
                val: 'Una volta',
            }],
            fields: [{
                key: "point",
                label: "Punto",
            },{
                key: 'pointname',
                label: 'Titolo',
            },{
                key: 'orderstandardspoint',
                label: 'Ordine',
            },{
                key: 'needyard',
                label: 'Cantiere',
            },{
                key: 'multisite',
                label: 'Multisito',
            },{
                key: "id",
                label: "",
            }],
            fieldsPointLanguage: [{
                key: "point",
                label: "Punto",
            },{
                key: 'lang',
                label: 'Lingua',
            },{
                key: 'report',
                label: "Report",
            }],
            notApplicableCombo: [{
                id: true,
                val: 'Escludibile',
            },{
                id: false,
                val: 'Non Escludibile',
            }]
        }
    },
    computed: {
        winHeight (){
            return this.$store.getters.getWinHeight;
        },
    },
    methods: {
        loadData(id){
            this.openHttp("standards_standard", { id: id }).then((result) => {
                result.standard.iafcodesSupport = this.convertCombo(result.iafcodes, result.standard.iafcodes);
                result.standard.riskslevelsSupport = this.convertCombo(result.riskslevels, result.standard.riskslevels);
                this.data = result;
                this.show = true;
            })
        },
        pointAdd(){
            this.data.standard.standardspoints.push({
                id: 0, 
                point: null,
                pointname: null,
                orderstandardspoint: 99,
                notapplicable: false,
                needyard: false,
                multisite: false,
            });
        },
        saveData(){
            this.saveHttp("standards_standard_save", this.data.standard, "Norma salvata!" ).then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("standards_standard_delete", { id: this.data.standard.id }, "Norma eliminata!" ).then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>
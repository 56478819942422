<template>
    <div>
        <b-modal v-model="show" v-if="data !== null" title="Faqe">
            <b-form-group label="Tipologjia" v-show="data.site.id === 0">
                <b-form-select v-model="data.site.yard" :options="yardCombo" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Faqe" v-show="data.site.yard === false">
                <b-form-select v-model="data.site.sites_id" :options="data.sites" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Adresë" v-show="data.site.yard === true">
                <b-form-input v-model="data.site.address"/>
            </b-form-group>
            <b-form-group label="Qyteti" v-show="data.site.yard === true">
                <b-form-input v-model="data.site.city"/>
            </b-form-group>
            <b-form-group label="Aktivitet" v-show="data.site.yard === true">
              <b-form-textarea v-model="data.site.activities" rows="3"/>
            </b-form-group>
            <b-form-group label="Tipologji" v-show="data.site.yard === true">
                <b-form-select v-model="data.site.isdocumental" :options="isdocumentalCombo" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Punëtorët e përkohshëm" v-show="data.site.yard === true">
                <b-form-input type="number" v-model="data.site.temporaryworkers"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Ruaj</b-btn>
                    <b-btn variant="danger" v-show="data.site.id > 0" @click="deleteData">Fshije</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "plansite",
    data() {
        return {
            show: false,
            data: null,
            yardCombo: [{
                id: false,
                val: "Operacionale"
            },{
                id: true,
                val: "Vend ndërtimi"
            }],
            isdocumentalCombo: [{
                id: false,
                val: "Në fushë"
            },{
                id: true,
                val: "Dokumentar"
            }]
        }
    },
    methods: {
        loadData( id, audits_id ){
            this.show = false;
            this.openHttp("plans_site", { id: id, audits_id: audits_id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            if (this.data.site.yard === true){
                this.data.site.sites_id = null;
            } else {
                this.data.site.address = null;
                this.data.site.cities_id = null;
            }
            this.saveHttp("plans_site_save", this.data.site).then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("plans_site_delete", { id: this.data.site.id }).then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        }
    }
}
</script>
<template>
  <div>
    <b-btn variant="primary" class="mb-1 mr-2" @click="excelKpi('offers')">Excel Kërkesa për ofertë</b-btn>
    <b-btn variant="primary" class="mb-1 mr-2" @click="excelKpi('contracts')">Excel Kontrata</b-btn>
    <b-btn variant="primary" class="mb-1 mr-2" @click="excelKpi('certificates')">Excel Certifikatat</b-btn>
    <form method="post" action="/kpi.php" ref="downloadForm" target="_blank">
      <input type="hidden" name="token" :value="token"/>
      <input type="hidden" name="form" ref="valueForm"/>
    </form>
  </div>
</template>

<script>
export default {
  name: "kpi",
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    token (){
      return this.$store.getters.getToken;
    },
  },
  methods: {
    excelKpi(form){
      this.$refs.valueForm.value = form;
      this.$refs.downloadForm.submit();
    }
  }
}
</script>

<template>
  <div>
    <qualification ref="qualification" @update="loadQualifications"/>
    <b-btn class="mr-2" variant="primary" @click="loadQualifications">Rifresko</b-btn>
    <b-btn variant="success" @click="$refs.qualification.loadData(0)">I ri</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="qualifications.items.data" :fields="qualifications.fields" :busy="qualifications.isBusy" :no-local-sorting="true" :sort-by.sync="qualifications.search.sort.by" :sort-desc.sync="qualifications.search.sort.desc" @sort-changed="sortingChangeQualifications" @row-clicked="$refs.qualification.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Nuk ka rezultat</template>
    </b-table>
    <tablebottom :busy="qualifications.isBusy" :currentpage="qualifications.search.page" :total="qualifications.items.total" @updateCurrentPage="qualifications.search.page = $event" @loadData="loadQualifications"/>
  </div>
</template>

<script>
import qualification from './qualification.vue';

export default {
  name: "qualifications",
  components : { qualification },
  data() {
    return {
      qualifications: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {},
          sort: {
            by: 'fullname',
            desc: false,
          },
        },
        fields: [{
          key: 'fullname',
          label: "Emri i plotë",
          sortable: true,
        },{
          key: 'standard',
          label: 'Standarde',
          sortable: true,
        },{
          key: 'usersrole',
          label: 'Roli',
          sortable: true,
        },{
          key: 'iafcodes',
          label: 'Kodet iaf',
        }],
        items: {
          total: 0,
          data: [],
        }
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadQualifications();
  },
  methods: {
    rowClassQualifications(item) {
      if ( item !== null ) return item.experts === null && item.auditors === null ? "table-danger" : "";
    },
    loadQualifications(){
      this.qualifications.isBusy = true;
      this.openHttp("qualifications_qualifications", this.qualifications.search ).then((result) => {
        this.qualifications.items = result;
        this.qualifications.isBusy = false;
      })
    },
    sortingChangeQualifications(ctx) {
      this.qualifications.search.sort.by = ctx.sortBy;
      this.qualifications.search.sort.desc = ctx.sortDesc;
      this.loadQualifications();
    }
  }
}
</script>
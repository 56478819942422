<template>
  <div>
    <company ref="company" @update="loadData(true)"/>
    <b-modal v-model="showFilter" title="Filtra">
      <b-form-group label="Kompani">
        <b-form-input v-model="tempFilter.company"/>
      </b-form-group>
      <b-form-group label="NIPT">
        <b-form-input v-model="tempFilter.vat"/>
      </b-form-group>
      <b-form-group label="Punonjesi">
        <b-form-input v-model="tempFilter.lastname"/>
      </b-form-group>
      <b-form-group label="Kontakti">
        <b-form-input v-model="tempFilter.contact"/>
      </b-form-group>
      <b-form-group label="Supplier">
        <b-form-input v-model="tempFilter.supplier"/>
      </b-form-group>
      <b-form-group label="IAF">
        <b-form-input v-model="tempFilter.iaf"/>
      </b-form-group>
      <b-form-group label="City">
        <b-form-input v-model="tempFilter.city"/>
      </b-form-group>
      <b-form-group label="State">
        <b-form-input v-model="tempFilter.state"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="filterSet">Kerko</b-btn>
          <b-btn variant="warning" @click="filterReset">Rifillo</b-btn>
        </div>
      </template>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData">Rifresko</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="showFilter = true">Filtro</b-btn>
    <b-btn class="mr-2" variant="success" @click="$refs.company.loadData(0)">E re</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sort.by" :sort-desc.sync="search.sort.desc" @sort-changed="sortingChange" @row-clicked="$refs.company.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Nuk ka rezultat</template>
    </b-table>
    <tablebottom :busy="isBusy" :currentpage="search.page" :total="items.total" @updateCurrentPage="search.page = $event" @loadData="loadData()"/>
    <form ref="excelForm" method="POST" action="excel.php" target="_blank">
      <input type="hidden" name="data" ref="excelFormData"/>
    </form>
  </div>
</template>

<script>
export default {
  name: "companies",
  data() {
    return {
      isBusy: true,
      showFilter: false,
      search: {
        page: 1,
        filters: {
          company: null,
          vat: null,
          contact: null,
          lastname: null,
          supplier: null,
          iaf: null,
          city: null,
          state: null,
        },
        sort: {
          by: null,
          desc: false,
        },
      },
      tempFilter: {
        company: null,
        vat: null,
        contact: null,
        lastname: null,
        supplier: null,
        iaf: null,
        city: null,
        state: null,
      },
      fields: [{
        key: 'company',
        label: "Kompani",
        sortable: true,
      },{
        key: 'vat',
        label: "NIPT",
        sortable: true,
      },{
        key: 'statename',
        label: "State",
        sortable: true,
      },{
        key: 'supplier',
        label: "Supplier",
        sortable: true,
      },{
        key: 'city',
        label: "Qyteti",
        sortable: false,
      },{
        key: 'iafs',
        label: "IAF",
        sortable: false,
      },{
        key: 'mails',
        label: 'Emailet',
      },{
        key: 'phones',
        label: 'Telefonat',
      }],
      items: {
        total: 0,
        data: [],
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData( notScroll = false ){
      if (notScroll == false) this.isBusy = true;
      this.openHttp("companies_companies", this.search).then((result) => {
        this.isBusy = false;
        this.items = result;
      })
    },
    filterSet(){
      this.showFilter = false;
      this.search.page = 1;
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        company: null,
        vat: null,
        contact: null,
        lastname: null,
        supplier: null,
        iaf: null,
        city: null,
        state: null,
      }
      this.filterSet();
    },
    sortingChange(ctx) {
      this.search.sort.by = ctx.sortBy;
      this.search.sort.desc = ctx.sortDesc;
      this.loadData();
    }
  }
}
</script>
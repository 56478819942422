import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import router from './router'
import store from './store'
import axios from 'axios'
Vue.use(axios)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect);

import VueSignature from 'vue-signature-pad';
Vue.use( VueSignature );

import VueQrcode from '@chenfengyuan/vue-qrcode';
Vue.component(VueQrcode.name, VueQrcode);

import company from './components/companies/company.vue';
import offer from './components/offers/offer.vue';
import contract from './components/contracts/contract.vue';
import plan from './components/plans/plan.vue';
import checklist from './components/checklists/checklist.vue';
import veto from './components/vetos/veto.vue';
import certificate from './components/certificates/certificate.vue';
import tablebottom from './components/tablebottom.vue';

Vue.component("company", company);
Vue.component("offer", offer);
Vue.component("contract", contract);
Vue.component("plan", plan);
Vue.component("checklist", checklist);
Vue.component("veto", veto);
Vue.component("certificate", certificate);
Vue.component('tablebottom', tablebottom);

Vue.config.productionTip = false

new Vue({ router, store, render: h => h(App), }).$mount('#app')
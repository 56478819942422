<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Educazione" @hidden="closeForm()" @close="closeForm()">
      <b-row>
        <b-col lg="4">
          <b-form-group label="Inizio">
            <b-form-input v-model="data.education.fromdate" type="date"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Fine">
            <b-form-input v-model="data.education.todate" type="date"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Scuola">
        <b-form-input v-model="data.education.school"/>
      </b-form-group>
      <b-form-group label="Disciplina">
        <b-form-input v-model="data.education.discipline"/>
      </b-form-group>
      <b-form-group label="Titolo">
        <b-form-input v-model="data.education.title"/>
      </b-form-group>
      <b-form-group label="Norme">
        <multiselect v-model="standardsSupport" :options="data.standards" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.education.standards = updateCombo(standardsSupport)"/>
      </b-form-group>
      <b-form-group label="IAFs">
        <multiselect v-model="iafcodesSupport" :options="data.iafcodes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.education.iafcodes = updateCombo(iafcodesSupport)"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.education.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "curriculumeducation",
  data() {
    return {
      show: false,
      data: null,
    }
  },
  methods: {
    loadData(usersId, id){
      this.openHttp("curricula_education", { id: id, users_id: usersId } ).then((result) => {
        this.standardsSupport = this.convertCombo(result.standards, result.education.standards);
        this.iafcodesSupport = this.convertCombo(result.iafcodes, result.education.iafcodes);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("curricula_education_save", this.data.education, "Educazione salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("curricula_education_delete", { id: this.data.education.id }, "Educazione eliminata!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    closeForm( reload = false ){
      this.data = null;
      this.show = false;
      if (reload === true) this.$emit('update');
    }
  }
}
</script>
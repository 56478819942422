<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" size="lg" title="Accreditation" scrollable @hidden="closeForm" @close="closeForm">
            <b-form-group label="Standard">
                <b-form-input v-model="data.accredation.standard"/>
            </b-form-group>
            <b-form-group label="IAF Codes">
                <b-form-checkbox-group v-model="data.accredation.iafcodes">
                    <b-form-checkbox v-for="item of data.iafcodes" :key="item.id" :value="item.id">{{ item.val }}</b-form-checkbox>
                </b-form-checkbox-group>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" @click="saveData">Salva</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "accreditation",
    data() {
        return {
            show: false,
            data: null,
        }
    },
    computed: {
        winHeight (){
            return this.$store.getters.getWinHeight;
        },
    },
    methods: {
        loadData( id ){
            this.openHttp("standards_accreditation", { id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("standards_accreditation_save", this.data.accredation, "Accreditamento salvato!" ).then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>
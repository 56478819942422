<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Certifikatë" scrollable @hidden="closeForm" @close="closeForm">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Certifikatë" active>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Kompani">
                  <b-form-input v-model="data.certificate.company"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Kontrata">
                  <b-form-input v-model="data.certificate.contract"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Lloji i kontratës">
                  <b-form-input v-model="data.certificate.contracttype"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Standarde">
                  <b-form-input v-model="data.certificate.standard"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Data e kontratës">
                  <b-form-input type="date" v-model="data.certificate.contract_date"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Data e skadimit">
                  <b-form-input type="date" v-model="data.certificate.expiry_date"/>
                </b-form-group>
              </b-col>
              <b-col lg="8">
                <b-form-group label="IAFs">
                  <b-form-input v-model="data.iafs"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Faqet">
              <b-table no-border-collapse :items="data.sites" :fields="fields.sites"/>
            </b-form-group>
            <b-form-group label="Fushëveprimi">
              <b-table no-border-collapse :items="data.scopes" :fields="fields.scopes"/>
            </b-form-group>
            <b-row>
              <b-col lg="2">
                <b-form-group label="Statusi">
                  <b-form-select v-model="data.certificate.active" :options="comboActive" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Kodi i certifikatës">
                  <b-form-input v-model="data.certificate.certificatecode" :disabled="!data.certificate.active"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Lëshimi i parë i certifikatës">
                  <b-form-input type="date" v-model="data.certificate.certificatefirstissue" :disabled="!data.certificate.active"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Lëshimi i certifikatës">
                  <b-form-input type="date" v-model="data.certificate.certificateissue" :disabled="!data.certificate.active"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Certifikatë e vlefshme nga">
                  <b-form-input type="date" v-model="data.certificate.certificatevalidfrom" :disabled="!data.certificate.active"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Skadimi i certifikatës">
                  <b-form-input type="date" v-model="data.certificate.certificateexpirancy" :disabled="!data.certificate.active"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Dokumenta">
            <certificatedocument ref="document" @update="loadData(data.certificate.id)"/>
            <b-btn variant="info" class="mr-2" size="sm" @click="prepareDocuments">Gjeneron</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 205)+ 'px'" class="mt-3" :items="data.documents" :fields="fields.documents" @row-clicked="this.$refs.document.loadData(data.certificate.id, $event.id)">
              <template v-slot:cell(id)="row">
                <b-btn variant="primary" size="sm" @click="$refs.document.downloadDocument(row.item.id)">Shkarko</b-btn>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="loadData(data.certificate.id)">Përditëso</b-btn>
          <b-btn variant="success" @click="saveData">Ruaj</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import certificatedocument from './certificatedocument.vue';

export default {
  name: "certificate",
  components: { certificatedocument },
  data() {
    return {
      show: true,
      data: null,
      comboActive: [{
        id: true,
        val: "Aktive"
      },{
        id: false,
        val: "Joaktiv"
      }],
      fields: {
        sites: [{
          key: 'address',
          label: "Adresë",
        },{
          key: 'city',
          label: 'Qyteti',
          sortable: true,
        },{
          key: 'postalcode',
          label: 'Kodi Postar',
          sortable: true,
        },{
          key: 'region',
          label: "Rajon",
          sortable: true,
        },{
          key: "state",
          label: "Shtetit",
          sortable: true,
        },{
          key: 'registered',
          label: 'Regjistruar',
          sortable: true,
          formatter: value => {
            return value === true ? 'Po' : 'Nr';
          },
        }],
        scopes: [{
          key: 'lang',
          label: 'Gjuha',
        },{
          key: 'scope',
          label: 'Fushëveprimi',
        }],
        certificates: [{
          key: 'standard',
          label: "Standard",
        },{
          key: 'certificatecode',
          label: 'Certifikata',
        },{
          key: 'certificatefirstissue',
          label: 'Numri 1',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'certificateissue',
          label: 'Çështje',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'certificatevalidfrom',
          label: 'Vlefshme nga',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'certificateexpirancy',
          label: 'Afati i fundit',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        }],
        documents: [{
          key: "filename",
          label: "Skedari",
          sortable: true,
        },{
          key: "datetime",
          label: "Përditëso",
          formatter: value => {
            let d = new Date(value);
            return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':') + " " + [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          sortable: true,
        },{
          key: "id",
          label: "",
        }],
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData( id ){
      if ( this.data === null ) this.show = false;
      this.openHttp("certificates_certificate", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    prepareDocuments(){
      this.saveHttp("certificates_documentprepare", { id: this.data.certificate.id }).then((result) => {
        if ( result.result == true ) this.loadData( this.data.certificate.id );
      })
    },
    saveData(){
      this.saveHttp("certificates_certificate_save", this.data.certificate).then((result) => {
        if ( result.result == true ) this.loadData(result.id);
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>
<template>
  <div>
    <checklist ref="checklist" @update="loadData"/>
    <b-btn variant="primary" @click="loadData">Rifresko</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" responsive hover show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sort.by" :sort-desc.sync="search.sort.desc" @sort-changed="sortingChange" @row-clicked="$refs.checklist.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Nuk ka rezultat</template>
    </b-table>
    <tablebottom :busy="isBusy" :currentpage="search.page" :total="items.total" @updateCurrentPage="search.page = $event" @loadData="loadData"/>
  </div>
</template>

<script>
export default {
  name: "checklists",
  data() {
    return {
      isBusy: true,
      showFilter: false,
      search: {
        page: 1,
        filters: {},
        sort: {
          by: 'company',
          desc: false,
        },
      },
      fields: [{
        key: 'company',
        label: 'Agjencia',
        sortable: true,
      },{
        key: 'standardsphases',
        label: 'Standardet dhe fazat',
      }],
      items: {
        total: 0,
        data: [],
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.isBusy = true;
      this.openHttp("checklists_checklists", this.search).then((result) => {
        this.isBusy = false;
        this.items = result;
      })
    },
    sortingChange(ctx) {
      this.search.sort.by = ctx.sortBy;
      this.search.sort.desc = ctx.sortDesc;
      this.loadData();
    }
  }
}
</script>
<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="info">
      <b-navbar-brand to="/homepage" v-show="checkRoute(['homepage'])">AQS Cert</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item v-show="checkRoute(['companies'])" to="/companies">Kompani</b-nav-item>        
          <b-nav-item v-show="checkRoute(['offers'])" to="/offers">Kërkesa për ofertë</b-nav-item>
          <b-nav-item v-show="checkRoute(['contracts'])" to="/contracts">Kontrata</b-nav-item>
          <b-nav-item v-show="checkRoute(['plans'])" to="/plans">Plani i auditimit</b-nav-item>
          <b-nav-item v-show="checkRoute(['checklists'])" to="/checklists">Checklist</b-nav-item>
          <b-nav-item v-show="checkRoute(['vetos'])" to="/vetos">Komiteti Teknik</b-nav-item>
          <b-nav-item v-show="checkRoute(['certificates'])" to="/certificates">Certifikatat</b-nav-item>
          <b-nav-item-dropdown text="Auditors" v-show="checkRoute(['curricula', 'qualifications'])">
            <b-dropdown-item to="/curricula" v-show="checkRoute(['curricula'])">Curricula</b-dropdown-item>
            <b-dropdown-item to="/qualifications" v-show="checkRoute(['qualifications'])">Kualifikime</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="Settings" v-show="checkRoute(['standards', 'laws', 'kpi', 'users'])">
            <b-dropdown-item to="/standards" v-show="checkRoute(['standards'])">Standarti</b-dropdown-item>
            <b-dropdown-item to="/laws" v-show="checkRoute(['laws'])">Ligje</b-dropdown-item>
            <b-dropdown-item to="/kpi" v-show="checkRoute(['kpi'])">KPI</b-dropdown-item>
            <b-dropdown-item to="/users" v-show="checkRoute(['users'])">Perdoruesi</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item to="/login" right>Log Out</b-nav-item>        
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "navbar",
  computed: {
    routesAllowed (){
      return this.$store.getters.getRoutes;
    }
  },
  methods: {
    checkRoute(routeToCheck){
      return this.routesAllowed.filter(value => -1 !== routeToCheck.indexOf(value)).length > 0;
    }
  }
}
</script>
<template>
  <div>
    <b-modal v-model="show" v-if="data !== null" title="Contact">
      <b-form-group label="Contact">
        <b-form-input v-model="data.contact.contact"/>
      </b-form-group>
      <b-form-group label="Type">
        <b-form-select v-model="data.contact.contactstypes_id" :options="data.contactstypes" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Worker">
        <b-form-select v-model="data.contact.workers_id" :options="data.workers" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
          <b-btn variant="danger" v-show="data.contact.id > 0" @click="deleteData">Delete</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "companycontact",
  data() {
    return {
      show: false,
      data: null,
    }
  },
  methods: {
    loadData( id, companies_id ){
      this.openHttp("companies_contact", { id: id, companies_id: companies_id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("companies_contact_save", this.data.contact, "Contact saved!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){      
      this.deleteHttp("companies_contact_delete", { id: this.data.contact.id }, "Contact deleted!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    }
  },
}
</script>
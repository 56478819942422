<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Checklist" scrollable @hidden="closeForm" @close="closeForm">
      <checklistopportunitytoclose ref="opportunitytoclose" @update="loadData(data.id)"/>
      <checkliststrength ref="strength" @update="loadData(data.id)"/>
      <checklistopportunity ref="opportunity" @update="loadData(data.id)"/>
      <checklistnonconformity ref="nonconformity" @update="loadData(data.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Takimi hapës" active>
            <b-table-simple responsive v-for="(item, index) of data.cover" :key="index">
              <b-thead head-variant="light">
                <b-tr>
                  <b-th style="width: 25%">Parametri</b-th>
                  <b-th>Vlera</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td>Numri i kompanisë dhe TVSH-së</b-td>
                  <b-td>{{ item.company + ' - ' + item.vat }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Fushëveprimi</b-td>
                  <b-td>
                    <div v-for="(item, index) of item.scopes" :key="index"><strong>{{ item.lang }}: </strong> {{ item.scope }}</div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>IAF</b-td>
                  <b-td>{{ item.iafcodes }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Faqet në certifikatë</b-td>
                  <b-td><div v-for="(item, index) of item.contractssites" :key="index">{{ item }}</div></b-td>
                </b-tr>
                <b-tr>
                  <b-td>Standardet dhe fazat</b-td>
                  <b-td>
                    <div v-for="(item, index) of item.standardsphases" :key="index">{{ item.standard + ": " + item.phases }}</div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>Pikat nuk duhet verifikuar</b-td>
                  <b-td>
                    <div v-for="(item, index) of item.exclusions" :key="index">{{ item.standard + ': ' + item.exclusion }}</div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>Faqet e audituara</b-td>
                  <b-td>
                    <div v-for="(item, index) of item.auditssites" :key="index">{{ item }}</div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-tab>
          <b-tab title="Planifikimi Planifikimi">
            <checklistreservation ref="reservation" @update="loadData(data.id)"/>
            <b-table-simple hover responsive>
              <b-thead>
                <b-tr>
                  <b-th>Nga</b-th>
                  <b-th>Te</b-th>
                  <b-th>Tipologjia</b-th>
                  <b-th>Ekipi</b-th>
                  <b-th>Pikat</b-th>
                  <b-th>Faqe</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-for="(item, index) of data.reservations">
                  <b-tr :key="index + '_tab'">
                    <b-th colspan="6">
                      <b-btn class="mr-2" size="sm">{{ item.dateclean }}</b-btn>
                      <b-btn class="mr-2" size="sm" :variant="item.todo === item.done ? 'success' : 'warning'">{{ (item.todo - item.done) !== 0 ? ('Ato mungojnë ' + (item.todo - item.done) + 'h') : 'OK' }}</b-btn>
                      <b-btn variant="primary" size="sm" @click="$refs.reservation.loadData( data.id, item.date, 0 )">I ri</b-btn>
                    </b-th>
                  </b-tr>
                  <b-tr v-for="(item2, index2) of item.reservations" :key="index + '_tab_' + index2" @click="$refs.reservation.loadData( data.id, item.date, item2.id)">
                    <b-td>{{ item2.fromtime }}</b-td>
                    <b-td>{{ item2.totime }}</b-td>
                    <b-td>{{ item2.auditstype }}</b-td>
                    <b-td>
                      <div v-for="(item3, index3) of item2.usersroles" :key="index3">
                        <nobr><strong>{{ item3.user + ' ' + item3.role }}</strong></nobr> {{ item3.standardsphases }}
                      </div>
                    </b-td>
                    <b-td>
                      <div v-for="(item3, index3) of item2.standardspoints" :key="index3">
                        <nobr><strong>{{ item3.standard }}</strong> {{ item3.points }}</nobr>
                      </div>
                    </b-td>
                    <b-td>{{ item2.site }}</b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </b-tab>
          <b-tab title="Pjesëmarrësit">
            <b-table responsive :items="data.workers" :fields="fields.workers">
              <template v-slot:cell(signature)="row">
                <b-btn v-show="row.item.signature === true" variant="success" size="sm" @click="markWorker(row.item.id, !row.item.signature)">I pranishëm</b-btn>
                <b-btn v-show="row.item.signature === false" variant="danger" size="sm" @click="markWorker(row.item.id, !row.item.signature)">Jo i pranishëm</b-btn>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Dëshmi">
            <b-table responsive :items="data.report" :fields="fields.report" :sticky-header="(winHeight - 300) + 'px'">
              <template v-slot:cell(languages)="row">
                <b-form-group :label="row.item.point + ' ' + item.lang" v-for="item in row.item.languages" :key="item.id">
                  <b-form-textarea v-model="item.report" rows="10" @change="saveEvidence(row.item.id, item.id, $event)"/>
                </b-form-group>
              </template>            
            </b-table>
          </b-tab>
          <b-tab title="Informazioni aggiuntive">
            <b-table responsive :items="data.additionalinfotypes" :fields="fields.additionalinfotypes" :sticky-header="(winHeight - 300) + 'px'">
              <template v-slot:cell(infotype)="row">
                <b-form-group :label="row.item.infotype">
                  <b-form-textarea v-model="row.item.evidences" rows="3" @change="saveAdditionalinfotypes(row.item.id, $event)"/>
                </b-form-group>
              </template>            
            </b-table>
          </b-tab>
          <b-tab :title="'Mundësitë për t\'u mbyllur ' + data.opportunitiestoclose.length">
            <b-table responsive :items="data.opportunitiestoclose" :fields="fields.opportunitiestoclose" :sticky-header="(winHeight - 300) + 'px'" @row-clicked="loadOpportunitytoclose($event.id)" >
              <template v-slot:cell(standardspoints)="row">
                <div v-for="(item, index) of row.value" :key="index"><nobr>{{ item }}</nobr></div>
              </template>
            </b-table>  
          </b-tab>
          <b-tab :title="'Pikat e forta ' + data.strengths.length">
            <b-btn variant="success" size="sm" class="mb-2" @click="loadStrength(0, [])">E re</b-btn>
            <b-table responsive :items="data.strengths" :fields="fields.strengths" :sticky-header="(winHeight - 300) + 'px'" @row-clicked="loadStrength($event.id, [])" >
              <template v-slot:cell(standardspoints)="row">
                <div v-for="(item, index) of row.value" :key="index"><nobr>{{ item }}</nobr></div>
              </template>
            </b-table>
          </b-tab>
          <b-tab :title="'Mundësitë ' + data.opportunities.length">
            <b-btn variant="success" size="sm" class="mb-2" @click="loadOpportunity(0, [])">E re</b-btn>
            <b-table responsive :items="data.opportunities" :fields="fields.opportunities" :sticky-header="(winHeight - 300) + 'px'" @row-clicked="loadOpportunity($event.id, [])" >
              <template v-slot:cell(standardspoints)="row">
                <div v-for="(item, index) of row.value" :key="index"><nobr>{{ item }}</nobr></div>
              </template>
            </b-table>
          </b-tab>
          <b-tab :title="'Jo konformitet ' + data.nonconformities.length">
            <b-btn variant="success" size="sm" class="mb-2" @click="loadNonconformity(0, [])">E re</b-btn>
            <b-table responsive :items="data.nonconformities" :fields="fields.nonconformities" :sticky-header="(winHeight - 300) + 'px'" @row-clicked="loadNonconformity($event.id, [])" >
              <template v-slot:cell(standardspoints)="row">
                <div v-for="(item, index) of row.value" :key="index"><nobr>{{ item }}</nobr></div>
              </template>
            </b-table>
          </b-tab>
          <b-tab :title="'Dokumentet ' + data.documents.length">
            <checklistsdocument ref="document" @update="loadData(data.id)"/>
            <b-btn variant="info" class="mr-2" size="sm" @click="prepareDocuments">Gjeneroni</b-btn>
            <b-table class="mt-2" :sticky-header="(winHeight - 300) + 'px'" :tbody-tr-class="rowClassDocuments" hover responsive :items="data.documents" :fields="fields.documents" @row-clicked="loadDocument($event.id)">
              <template v-slot:cell(id)="row">
                <b-btn variant="primary" v-show="row.item.check == true" size="sm" @click="downloadDocument(row.item.id)">Shkarko</b-btn>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="loadData(data.id)">Rifresko</b-btn>
          <b-btn variant="warning" @click="nextStepData">Mbyll auditimet</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import checklistreservation from './checklistcomponents/checklistreservation.vue';
import checklistopportunitytoclose from './checklistcomponents/checklistopportunitytoclose.vue';
import checkliststrength from './checklistcomponents/checkliststrength.vue';
import checklistopportunity from './checklistcomponents/checklistopportunity.vue';
import checklistnonconformity from './checklistcomponents/checklistnonconformity.vue';
import checklistsdocument from './checklistcomponents/checklistsdocument.vue';

export default {
  name: "checklist",
  components : { checklistreservation, checklistopportunitytoclose, checkliststrength, checklistopportunity, checklistnonconformity, checklistsdocument },
  data() {
    return {
      show: false,
      data: null,
      fields: {
        workers: [{
          key: "fullname",
          label: "Emri i plotë",
        },{
          key: "job",
          label: "Punë",
        },{
          key: "signature",
          label: "Nënshkrimi",
        }],
        report: [{
          key: "languages",
          label: "Raporti",
        }],
        additionalinfotypes: [{
          key: "infotype",
          label: "infotype",
        }],
        opportunitiestoclose: [{
          key: "opportunity",
          label: "Mundësi",
        },{
          key: "opportunityclosure",
          label: "Mbyllja",
        },{
          key: "standardspoints",
          label: "Pikat e Standardeve",
        }],
        strengths: [{
          key: "strength",
          label: "Forcë",
        },{
          key: "standardspoints",
          label: "Pikat e Standardeve",
        }],
        opportunities: [{
          key: "opportunity",
          label: "Mundësi",
        },{
          key: "standardspoints",
          label: "Pikat e Standardeve",
        }],
        nonconformities: [{
          key: "nonconformity",
          label: "Mospërputhje",
        },{
          key: "standardspoints",
          label: "Pikat e Standardeve",
        }],
        certificates: [{
          key: "standard",
          label: "Standarde",
        },{
          key: "certificatecode",
          label: "Pikat e Standardeve",
        },{
          key: "certificateissue",
          label: "Pikat e Standardeve",
        },{
          key: "certificatevalidfrom",
          label: "Pikat e Standardeve",
        },{
          key: "certificateexpirancy",
          label: "Pikat e Standardeve",
        }],
        documents: [{
          key: "filename",
          label: "file",
          sortable: true,
        },{
          key: "datetime",
          label: "date & time",
          formatter: value => {
            let d = new Date(value);
            return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':') + " " + [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          sortable: true,
        },{
          key: "id",
          label: "",
        }],
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  methods: {
    rowClassSignature(item, type) {
      if (!item || type !== 'row') return
      return item.signature === false ? 'table-danger' : '';
    },
    rowClassDocuments(item) {
      return item !== null && item.check === false ? 'table-danger' : '';
    },
    loadData(id){
      if ( this.data === null ) this.show = false;
      this.openHttp("checklists_checklist", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    markWorker( id, status ){
      this.saveHttp("checklists_worker_present", { id: id, audits_id: this.data.id, status: status } ).then((result) => {
        if ( result.result === true ) this.loadData(this.data.id);
      })
    },
    loadOpportunitytoclose( id ){
      this.$refs.opportunitytoclose.loadData( id, this.data.id );
    },
    loadStrength( id ){
      this.$refs.strength.loadData( id, this.data.id );
    },
    loadOpportunity( id ){
      this.$refs.opportunity.loadData( id, this.data.id );
    },
    loadNonconformity( id ){
      this.$refs.nonconformity.loadData( id, this.data.id );
    },
    loadDocument( id ){
      this.$refs.document.loadData( this.data.id, id );
    },
    downloadDocument( id ){
      this.$refs.document.download(id);
    },
    saveEvidence( standardsPointsId, languagesId, report ){
      this.saveHttp("checklists_evidence_save", { audits_id: this.data.id, standardspoints_id: standardsPointsId, languages_id: languagesId, report: report } ).then((result) => {
        if ( result.result === true ) this.loadData(this.data.id);
      })
    },
    saveAdditionalinfotypes(additionalsId, evidences){
      this.saveHttp("checklists_additionals_save", { audits_id: this.data.id, additionals_id: additionalsId, evidences: evidences } ).then((result) => {
        if ( result.result === true ) this.loadData(this.data.id);
      })
    },
    prepareDocuments(){
      this.saveHttp("checklists_documents_generate", { id: this.data.id }).then((result) => {
        if ( result.result === true ) this.loadData( this.data.id );
      })
    },
    nextStepData(){
      this.deleteHttp("checklists_next_step", { id: this.data.id } ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    }
  }
}
</script>
